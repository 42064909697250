import LogocromGif from "assets/video/Logocrom.gif";
import Logocrom from "assets/logos/froin-3d.svg";
// import LeftNetworkBg from "assets/images/left_network_bg.png";
// import RightNetworkBg from "assets/images/right_network_bg.png";
import LogoFroin from "assets/logos/LogoFroinBlanco.png";
// import UsaFlag from "assets/icons/usa_flag.png";
import ColFlag from "assets/icons/col_flag.png";
import PlayCircleImage from "assets/icons/play_circle.svg";
// import PPImage from "assets/logos/PP.png";
// import MPImage from "assets/logos/MP.png";
// import CRYPTOImage from "assets/logos/CRYPTO.png";
// import NQPAYImage from "assets/logos/NQ_PAY.png";
// import SadImage from "assets/icons/sad.svg";
import Logo from "assets/logos/IsoFroinBlanco.png";
// import ProfilePlaceholder from "assets/icons/profile_placeholder.svg";

export const usedImages = [
  LogocromGif,
  Logocrom,
  // LeftNetworkBg,
  // RightNetworkBg,
  LogoFroin,
  // UsaFlag,
  ColFlag,
  PlayCircleImage,
  // PPImage,
  // MPImage,
  // CRYPTOImage,
  // NQPAYImage,
  // SadImage,
  Logo,
  // ProfilePlaceholder,
]