import * as React from "react";
import { useContext, useEffect, useState } from "react";
import {
  CallMade,
  CallReceived,
  ChevronLeft,
  ChevronRight,
  CurrencyExchange,
  AccessTime,
  Cancel,
  CheckCircle,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

import { returnFormatNumber } from "utils/validations";
import pageStyles from "./styles/HistoryListStyles";
import CRYPTOImage from "assets/logos/CRYPTO.png";
import NQPAYImage from "assets/logos/NQ_PAY.png";
import { Store } from "hooks/main_store";
import PPImage from "assets/logos/PP.png";
import MPImage from "assets/logos/MP.png";
import Model from "hooks/Model";
import Api from "utils/api";

function HistoryList() {
  const { t } = useTranslation();
  const styles = pageStyles();
  const {
    state: { infoUser },
  } = useContext(Store);

  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const renderPaymentIcon = (method) => {
    switch (method) {
      case "PP":
        return (
          <img src={PPImage} alt="PayPal" className={styles.paymentIcon} />
        );
      case "MP":
        return (
          <img
            src={MPImage}
            alt="Mercado Pago"
            className={styles.paymentIcon}
          />
        );
      case "CRYPTO":
        return (
          <img
            src={CRYPTOImage}
            alt="Cryptomus"
            className={styles.paymentIcon}
          />
        );
      case "NQ_PAY":
        return (
          <img src={NQPAYImage} alt="nequi" className={styles.paymentIcon} />
        );
      default:
        break;
    }
  };

  const renderItems = () => {
    if (items.length < 1) {
      return (
        <p className={styles.noItemsLabel}>
          {t("pages.history.historyList.thereNo")}
        </p>
      );
    }

    return items.slice(currentPage * 5, currentPage * 5 + 5).map((data) => (
      <div className={styles.itemList} key={data?.uid}>
        <span className={styles.quantityItemList}>
          {returnFormatNumber(data.quantity)}
          <br />
          <span>$FROIN</span>
        </span>
        <div className={styles.rowItemList}>
          <p className={styles.personItemList}>
            <b>
              {data?.sender === infoUser?.email
                ? data?.receiver
                : data?.sender || t("pages.history.historyList.buy")}
            </b>
          </p>

          <p className={styles.dateItemList}>
            {data.status &&
              (data.status === "approved" ? (
                <CheckCircle className={styles.icon3ItemList} />
              ) : data.status === "rejected" ? (
                <Cancel className={styles.icon2ItemList} />
              ) : (
                <AccessTime className={styles.iconItemList} />
              ))}{" "}
            {data.created}
          </p>
        </div>
        {data.method && renderPaymentIcon(data.method)}
        {data.userId === infoUser.uid ? (
          <CurrencyExchange className={styles.icon3ItemList} />
        ) : data?.sender === infoUser?.email ? (
          <CallMade className={styles.iconItemList} />
        ) : (
          <CallReceived className={styles.icon2ItemList} />
        )}
      </div>
    ));
  };

  const changePage = (count) => {
    let newPage = currentPage + count;
    newPage = newPage < 0 ? 0 : newPage > totalPages ? totalPages : newPage;
    setCurrentPage(newPage);
  };

  const Pagination = () => (
    <>
      <div className={styles.rowPagination}>
        <button
          className={styles.leftContRowPagination}
          onClick={() => changePage(-1)}
        >
          <div className={styles.iconRowPagination}>
            <ChevronLeft />
          </div>
          <span className={styles.labelRowPagination}>
            {t("pages.history.historyList.back")}
          </span>
        </button>

        <span className={styles.countRowPagination}>
          {currentPage + 1} / {totalPages + 1}
        </span>

        <button
          className={styles.rightContRowPagination}
          onClick={() => changePage(1)}
        >
          <div className={styles.iconRowPagination}>
            <ChevronRight />
          </div>
          <span className={styles.labelRowPagination}>
            {t("pages.history.historyList.next")}
          </span>
        </button>
      </div>
    </>
  );

  const getHistory = async () => {
    try {
      Model.setData("loading", true);

      const response = await Api.post("/wallet/getHistory");

      setCurrentPage(0);
      setItems([]);

      if (response.ok) {
        const totalItems: any[] = (response.data || []) as any[];
        setItems(totalItems);
        setTotalPages(Math.ceil(totalItems.length / 5) - 1);
      } else {
        setTotalPages(0);
        throw new Error(response.data as string);
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  useEffect(() => {
    if (infoUser?.uid && !loaded) {
      setLoaded(true);
      setTimeout(getHistory, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoUser?.uid]);

  return (
    <>
      <h2 className={styles.title}>
        {t("pages.history.historyList.transactionHistory")}
      </h2>
      <p className={styles.description}>
        {t("pages.history.historyList.belowCanSee")}{" "}
        <a href="mailto:help@froin.co">
          {t("pages.history.historyList.support")}
        </a>
        .
      </p>
      <Pagination />
      {renderItems()}
    </>
  );
}

export default HistoryList;
