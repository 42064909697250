import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Policy } from "@mui/icons-material";
import { detectIncognito } from "detectincognitojs";

import pageStyles from "./styles";

const IncognitoAlert = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false)

  const styles = pageStyles();

  const firstLoad = async () => {
    const result = await detectIncognito();
    setShow(result.isPrivate)
  }

  useEffect(() => {
    firstLoad()
  }, [])

  if (show) {
    return (
      <div className={styles.container}>
        <Policy className={styles.icon} />
        <p className={styles.message}>
          {t("components.incognitoAlert.forLiveExp")}{" "}
          <b>{t("components.incognitoAlert.noUseIncognito")}</b>
        </p>
        <img
          alt="FROIN"
          src={require("../../assets/logos/LogoFroinBlanco.png")}
          className={styles.logo}
        />
      </div>
    );
  }

  return <></>
};

export default IncognitoAlert;
