const texts = {
  translation: {
    commons: {
      cancel: "Cancel",
      confirm: "Confirm",
      unknowError: "Unknown error",
    },
    components: {
      authModal: {
        acceptTerms: 'Before continuing you must accept our Terms & Conditions and Privacy Policy',
      },
      buyButtons: {
        anotherCountry: "🌎 Other country",
        purchaseNoConfirmed: "Purchase couldn't be confirmed",
        youMustEnter: "You must enter an amount greater than 1000 $FROIN",
        buyFroin: "Buy $FROIN",
        thankyou:
          "Thank you for your interest in our project, for now we are only receiving investments in personalized mode. \n\nTo make your purchase, write us an email to help@froin.co with the amount of {{quantity}} $FROIN that you wish to purchase and the desired payment method, so that our team will send you the payment link of the entity {{labelMethod}} and assign the tokens to your account when the purchase is confirmed.\n\nWe will soon enable payments on the website, thank you for your understanding.",
        gotIt: "Got it",
        paymentSent: "Payment request sent",
        weSentNotification:
          "We have sent a notification to your Nequi app with number {{phone}}, review the information and confirm the payment to disburse your $FROIN.",
        ifPaymentNoAppear: `If the payment window doesn't appear, click on the "Go to pay" button to be redirected to the payment platform.`,
        gotToPay: "Go to pay",
        enterPhone: "Enter your phone number",
        invalidPhoneFormat: "Phone number is invalid",
        weWaitingForPay:
          "We are waiting for you to continue with your process, review the information and confirm the payment to disburse your $FROIN.",
      },
      header: {
        beforeContinue: "Before continue ...",
        youWantLogout: "Do you want to log out?",
        home: "Home",
        receive: "Receive",
        history: "History",
        send: "Send",
        logOut: "Log out",
        ranking: "Ranking",
      },
      incognitoAlert: {
        forLiveExp: "To live the whole experience",
        noUseIncognito: "don't use incognito browsing",
      },
    },
    pages: {
      home: {
        accessAccount: {
          loggedIntoAccount: "You have successfully logged in",
          thankYou:
            "Thank you for being part of the FROIN community, you will receive updated information on news and new benefits that we are constantly working on to offer our community.",
          backHome: "Back to Home",
        },
        buyHome: {
          writeUs:
            "Write us an email to help@froin.co describing your case and, as soon as possible, our team will contact you.",
          enterValidEmail: "Enter a valid email",
          formatInvalid: "The format is invalid",
          helloAgain: "Hello again! 👋 If you need help, don't hesitate",
          contactUs: "contact us here.",
          currentlyBalance: "Currently in your account you have a balance of:",
          canBuyMore:
            "You can buy more tokens with the following payment methods, enter the amount (whole numbers), select your location and you will see the amount to pay in each enabled option:",
          youGoingBuy: "You are going to buy a quantity of:",
          howMany: "How many $FROIN do you want?",
          checkEmail: "Check your email inbox",
          andFollow: "and follow the instructions to enter.",
          enterEmail: "Enter your email to create or access your account",
          logIn: "LOG IN",
          buyFroin: "BUY $FROIN",
          pleaseEnterEmail:
            "Please enter your email again, make sure you use the same browser and without incognito mode",
          investCrypto:
            "Invest in the crypto project that will revolutionize the events and entertainment industry",
          ourProject:
            "Our project proposes to create a sustainable economy and attractive to new investors, rewarding the community that participates and uses our services. You can see more information below:",
          investNow: "INVEST NOW",
          invested: "invested",
          goal: "goal",
        },
        featuresHome: {
          benefits: "Benefits",
          atFroin:
            "At FROIN we care about offering the best benefits for our users, the community is important to us and that is why when using our services you can immediately access all the following benefits for using FROIN.",
          weAreWallet: "We are a digital WALLET",
          sendAndReceive:
            "Send and receive $FROIN to other users with just their email and keep your balance safe with no maintenance fee. Here you will also receive your rewards that you generate by participating in our Ranking",
          new: "New!",
          cashless: "CASHLESS Service",
          buyAndSell: "Buy and sell products on the platform",
          andReceive: "and receive discounts when paying with $FROIN.",
          comingSoon: "Coming Soon",
          yourMoney: "Your money at hand",
          ifWishWithdraw:
            "If you wish to withdraw your $FROIN balance to an account",
          or: "or",
          canContact: "you can contact us at",
          weWillGladly:
            "and we will gladly help you in the process of receiving it in less than 24 hours (it may vary depending on the payment method).",
          investEarn: "Invest and earn",
          ourCommunity:
            "Our community can invest their $FROIN balance to generate more tokens in a passive way. The APY reward percentage can vary between 6% to 20% of earnings.",
          moreBenefits: "MORE BENEFITS TO BE ANNOUNCED SOON",
        },
        bannerHome: {
          welcomeTitle: "Welcome to FROIN",
          welcomeDescription:
            "We are the technological project that works to revolutionize the experience in events and entertainment, based on promoting entrepreneurship to generate opportunities for the entire community",
          join: "Join now",
          introDescription:
            "Since 2023 we have focused on the idea of ​​starting to create digital products to revolutionize the digital experiences of the industry, that is why FROIN was born. We work with the objective of offering innovative services that can promote projects, brands, ventures, businesses, and all types of ideas that can benefit.\n\nBy being part of FROIN, you can participate in different ways depending on your objectives.",
          suggestedVideos: "Suggested content",
        },
        rolesInfo: {
          label: "How can I participate?",
          labelArtist: "Artist/Brand",
          descriptionArtist:
            "It's time to bring new experiences to your group of followers, fans, clients, etc. With “MULTIMEDIA PROMOTION” services to boost your project, you can offer a rewards program for each user who interacts with your content.",
          join: "Join now",
          labelViewer: "Viewer",
          descriptionViewer:
            "The large important part of the events and entertainment industry, which are the attendees and spectators, are not benefited in any way by their participation, that is why FROIN was born.\n\nIf you are a true fan, follower or active participant In the community, you will begin to receive rewards in $FROIN, which can be redeemed in purchases of products/services from our allied businesses, and soon for real money.",
        },
      },
      history: {
        historyList: {
          thereNo:
            "There are no transactions to show that you have made recently",
          back: "Back",
          next: "Next",
          transactionHistory: "Transaction history",
          belowCanSee:
            "Below you can see the list of your transactions with the details of each one.\n\nIf you need support, don't hesitate to write to our",
          support: "support channel",
          buy: "Buy tokens",
        },
      },
      page404: {
        weDidntFindPage: "We have not found the page you are looking for",
        getMeOut: "Get me out of here",
      },
      receive: {
        receiveView: {
          receive: "Receive",
          receiveTokens: "To receive tokens, you can share your email",
          orYouCan:
            "or you can scan the following QR code.\n\nIf you need support do not hesitate to write to our",
          support: "support channel",
          qrCode: "QR code of your wallet",
        },
      },
      send: {
        sendForm: {
          tokensSent: "Tokens sent successfully",
          enterAllData: "Enter all data",
          amountGreater: "Amount must be greater than 0 tokens",
          youCannotSend: "You cannot send tokens to yourself",
          noHaveEnough: "You don't have enough tokens",
          sendFroin: "Send $FROIN",
          doYouAgreeSend:
            "Do you agree to send {{quantity}} $FROIN to the user with the email {{email}}? The transaction will fail if the user is not registered on the platform. \n\nRemember that this action is not possible can reverse.",
          enterValidEmail: "Enter a valid email",
          ourSendSystem: "Our send system is",
          easyFast: "easy, fast and secure",
          theOnlyThing:
            "the only thing you have to do is enter the number of tokens to send, add the recipient's email and confirm the transaction.\n\nIf you need support, don't hesitate to write to our",
          support: "support channel",
          amountTokens: "Amount of tokens to send",
          howMany: "How many $FROIN are you sending?",
          recipientEmail: "Recipient's email",
        },
      },
    },
  },
};

export default texts;
