import { makeStyles } from "@mui/styles";
import { Commons, colors } from "styleguide";

const styles = makeStyles({
  backdrop: {
    zIndex: 1,
  },
  container: {
    ...Commons.flexCenter,
    position: "fixed",
    width: "100vw",
    height: "100vh",
    top: 0,
    left: 0,
    zIndex: 100,
  },
  card: {
    ...Commons.flexColumn,
    alignItems: "center",
    position: "relative",
    backgroundColor: colors.black,
    zIndex: 2,
    borderRadius: 30,
    boxShadow: `0 0 60px 0px ${colors.orange}`,
    padding: 60,
    textAlign: "center",
    maxWidth: "90vw",
    boxSizing: "border-box",
    [Commons.smallQuery]: {
      boxShadow: `0 0 30px 0px ${colors.orange}`,
      padding: 24,
    },
  },
  logo: {
    width: 150,
    [Commons.smallQuery]: {
      width: 90,
    },
  },
  message: {
    color: colors.white,
    textAlign: "center",
    fontSize: 18,
    maxWidth: 330,
    '& span': {
      fontWeight: '600',
      color: colors.orange
    }
  },
  submit: {
    ...Commons.defaultButton,
    backgroundColor: colors.orange,
    color: colors.white,
    textAlign: "center",
    fontSize: 18,
    fontWeight: 600,
    borderRadius: 12,
    padding: 18,
    margin: "42px 0 36px 0",
  },
  row: {
    ...Commons.flexRow,
  },
  check: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    width: 30,
    aspectRatio: "1 / 1",
    border: `2px solid ${colors.orange}`,
    borderRadius: 6,
  },
  checked: {
    backgroundColor: colors.orange
  },
  iconCheck: {
    color: colors.black,
    fontSize: '18px !important'
  },
  terms: {
    color: colors.white,
    textAlign: "left",
    fontSize: 14,
    maxWidth: 330,
    margin: "0 0 0 12px",
  },
  linkTerms: {
    color: colors.orange,
    textDecoration: 'underline',
  },
});

export default styles;
