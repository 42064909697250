import * as React from "react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Layout } from "components";
import Model from "hooks/Model";
import LocalStorage from "utils/localStorage";

function LayerLoadVideo() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();


  useEffect(() => {
    Model.setData('loading', true)
    const reloadedLayerLoadVideo = LocalStorage(false).getItem("reloadedLayerLoadVideo");
    let callback: any = null

    if (reloadedLayerLoadVideo === 'true') {
      LocalStorage(false).removeItem("reloadedLayerLoadVideo");
      callback = setTimeout(() => {
        navigate(`/detail-video-reward?id=${searchParams.get('id')}`, {replace: true})
      }, 500);
    } else {
      LocalStorage(false).setItem("reloadedLayerLoadVideo", "true");
      callback = setTimeout(() => {
        window.location.reload()
      }, 500);
    }

    return () => clearTimeout(callback)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Layout>
      <></>
    </Layout>
  );
}

export default LayerLoadVideo;
