import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { Backdrop } from "@mui/material";
import { detectIncognito } from "detectincognitojs";

import LocalStorage, {returnSessionStorage as SessionStorage} from "utils/localStorage";
import pageStyles from "./styles";
import { Store, StoreProps } from "hooks/main_store";

const InstallPWA = () => {
  const { state } = useContext<StoreProps>(Store);
  
  const styles = pageStyles();
  const [installPrompt, setInstallPrompt] = useState(null);
  const [hidePrompt, setHidePrompt] = useState(false);

  const disableInAppInstallPrompt = async () => {
    try {
      const result = await detectIncognito();
      LocalStorage(result.isPrivate).setItem("hideInstallPWA", "true");
      setHidePrompt(true);
      setInstallPrompt(null);
    } catch (error) {
      console.log("Error disableInAppInstallPrompt: ", error);
    } finally {
      disableListeners();
    }
  };

  const canInstalPwa = async (event) => {
    try {
      event.preventDefault();
      const result = await detectIncognito();
      const hideInstallPWA = LocalStorage(result.isPrivate).getItem(
        "hideInstallPWA"
      );
      const hideInstallPWASesion = SessionStorage(result.isPrivate).getItem(
        "hideInstallPWA"
      );

      setTimeout(() => {
        if (hideInstallPWA !== "true" && hideInstallPWASesion !== "true" && !hidePrompt) {
          setInstallPrompt(event);
        }
      }, 6000);
    } catch (error) {
      console.log("Error canInstalPwa: ", error);
    }
  };

  const onInstall = async () => {
    try {
      if (installPrompt?.prompt) {
        await installPrompt.prompt();
      }
    } catch (error) {
      console.log("Error on Install : ", error);
    } finally {
      setInstallPrompt(null);
      setHidePrompt(true);
      disableListeners();
    }
  };

  const disableListeners = () => {
    window.removeEventListener("beforeinstallprompt", canInstalPwa);
    window.removeEventListener("appinstalled", disableInAppInstallPrompt);
  };

  const handleCloseBackdrop = async () => {
    try {
      const result = await detectIncognito();
      SessionStorage(result.isPrivate).setItem("hideInstallPWA", "true");
      setHidePrompt(true);
      setInstallPrompt(null);
    } catch (error) {
      console.log("Error handleCloseBackdrop: ", error);
    } finally {
      disableListeners();
    }
  }

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", canInstalPwa);
    window.addEventListener("appinstalled", disableInAppInstallPrompt);

    return disableListeners;
    // eslint-disable-next-line
  }, []);

  const canInstall: boolean = installPrompt != null && state.isOlder

  return (
    <>
      <Backdrop
        open={canInstall}
        onClick={handleCloseBackdrop}
        className={styles.backdrop}
      />
      <div
        className={[
          styles.container,
          canInstall ? styles.showContainer : "",
        ].join(" ")}
      >
        <div className={styles.colText}>
          <p className={styles.title}>Instalar App 🤩</p>
          <p className={styles.text}>
            Agrega nuestra aplicación web a tu dispositivo y vive la experiencia
            de una manera más cómoda al alcance de tu mano 🔥 <br />
            <br />
            Seguimos innovando para nuestra comunidad 🚀
          </p>
        </div>
        <div className={styles.colButtons}>
          <button className={styles.downLoadBbutton} onClick={onInstall}>
            Descargar
          </button>
          <button
            className={styles.dismissButton}
            onClick={handleCloseBackdrop}
          >
            En otro momento
          </button>
          <button
            className={styles.hideButton}
            onClick={disableInAppInstallPrompt}
          >
            No volver a mostrar
          </button>
        </div>
      </div>
    </>
  );
}

export default InstallPWA;
