import * as React from "react";
import { Layout } from "components";
import HistoryList from "./HistoryList";

function HistoryListScreen() {
  return (
    <Layout withBg>
      <HistoryList />
    </Layout>
  );
}

export default HistoryListScreen;
