import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  logo: {
    height: 72,
    position: 'relative',
    display: 'block',
    margin: '60px auto',
    [Commons.smallQuery]: {
      margin: '60px auto 24px auto',
      height: 42
    }
  },
  backButton: {
    ...Commons.flexRow,
    ...Commons.defaultButton,
    backgroundColor: colors.orange,
    color: colors.white,
    fontSize: 24,
    borderRadius: 18,
    boxShadow: colors.shadowWhite,
    padding: '6px 60px',
    marginBottom: 60
  },
  animatedOnDiv: {
    animation: "$opacityInit 1s linear",
    opacity: 1,
    position: 'relative'
  },
  animatedOffDiv: {
    animation: "$opacityOut 1s linear",
    opacity: 0,
    position: 'relative'
  },
  "@keyframes opacityInit": {
    "0%": {
      opacity: 0
    },
    "100%": {
      opacity: 1
    },
  },
  "@keyframes opacityOut": {
    "0%": {
      opacity: 1
    },
    "100%": {
      opacity: 0
    },
  },
});

export default styles;
