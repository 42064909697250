const returnLocalStorage = (isIncognito = false) => {
  const dummyLocalStorage = {
    setItem: () => {},
    removeItem: () => {},
    getItem: () => {},
    clear: () => {},
    isIncognito: true,
  };

  try {
    if (!isIncognito) {
      return window?.localStorage || dummyLocalStorage;
    } else {
      return dummyLocalStorage;
    }
  } catch (e) {
    return dummyLocalStorage;
  }
};

export const returnSessionStorage = (isIncognito = false) => {
  const dummySessionStorage = {
    setItem: () => {},
    removeItem: () => {},
    getItem: () => {},
    clear: () => {},
    isIncognito: true,
  };

  try {
    if (!isIncognito) {
      const auxSessionStage = window?.sessionStorage || dummySessionStorage;
      return {
        setItem: (key, string) => {
          try {
            auxSessionStage.setItem(key, string);
          } finally {
            auxSessionStage.setItem(`${key}_updatedAt`, String(new Date()));
          }
        },
        removeItem: (key) => auxSessionStage.removeItem(key),
        getItem: (key) => auxSessionStage.getItem(key),
        clear: () => auxSessionStage.clear(),
        isIncognito: false,
      };
    } else {
      return dummySessionStorage;
    }
  } catch (e) {
    return dummySessionStorage;
  }
};

export default returnLocalStorage;
