import * as React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import ArtistContract from "assets/documents/Contrato_confidencialidad.pdf";
import DataAuthorizationFile from "assets/documents/Autorizacion_Data.pdf";
import FroinProposalFile from "assets/documents/froinProposal.pdf";
import PrivacyFile from "assets/documents/Politica_de_privacidad.pdf";
import TermsFiles from "assets/documents/Terminos_y_condiciones.pdf";
import pageStyles from "./styles/renderLegalDocStyles";
import Model from "hooks/Model";
import Layout from "components/Layout";
import { useContext } from "react";
import { Store } from "hooks/main_store";

const documents = {
  data: DataAuthorizationFile,
  artist: FroinProposalFile,
  terms: TermsFiles,
  privacy: PrivacyFile,
  artistContract: ArtistContract,
};

const RenderLegalDoc = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const nameDocument = urlParams.get("document") || "terms";
  const { state: { loading: globalLoading }, } = useContext(Store);
  
  // const { t } = useTranslation();
  const styles = pageStyles();
  const [numPages, setNumPages] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  };

  React.useEffect(() => {
    if (!globalLoading && loading) {
      Model.setData("loading", true);
    } else if (!loading) {
      Model.setData("loading", false);
    }
  }, [globalLoading, loading]);

  React.useEffect(() => {
    Model.setData("loading", true);
    Model.setData("opacity", 0.7);

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
  }, []);

  return (
    <Layout withBg>
      <Document
        file={documents[nameDocument] || documents.data}
        onLoadSuccess={onDocumentLoadSuccess}
        className={
          nameDocument === "artist" ? styles.container2 : styles.container
        }
      >
        {new Array(numPages).fill("page_").map((value, index) => (
          <Page pageNumber={index + 1} key={value + index} />
        ))}
        <div
          className={styles.cover}
          style={{
            maxHeight: numPages * (nameDocument === "artist" ? 4653 : 1035),
            height: `${numPages * (nameDocument === "artist" ? 4653 : 129)}${nameDocument === "artist" ? 'px' :'vw'}`,
          }}
        />
      </Document>
    </Layout>
  );
};

export default RenderLegalDoc;
