import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  title: {
    margin: 0,
    color: colors.orange,
    fontSize: 42,
    fontWeight: 600,
    [Commons.smallQuery]: {
      fontSize: 30,
    },
  },
  description: {
    color: colors.white,
    fontSize: 18,
    textAlign: "left",
    margin: "12px 0",
    whiteSpace: "break-spaces",
    "& a": {
      color: colors.orange,
      textDecoration: "none",
    },
  },
  labelInput: {
    color: colors.orange,
    fontSize: 18,
    fontWeight: 400,
    margin: "60px 0 0 0",
    [Commons.smallQuery]: {
      margin: "30px 0 0 0",
    },
  },
  contQr: {
    margin: "30px 0 0 0",
    backgroundColor: colors.white,
    padding: 6,
    width: "fit-content",
    boxShadow: colors.shadow,
    borderRadius: 6,
    display: "block",
    [Commons.smallQuery]: {
      margin: "30px auto 0 auto",
    },
  },
});

export default styles;
