import * as React from "react";
import { useContext, useEffect, useState } from "react";
import {
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Backdrop,
  Link,
} from "@mui/material";
import {
  Send,
  Menu,
  Close,
  SaveAlt,
  Home,
  History,
  EmojiEvents,
  Person,
  PlayCircleOutline
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { authService, databaseService } from "utils/firebase";
import LocalStorage from "utils/localStorage";
// import UsaFlag from "assets/icons/usa_flag.png";
// import ColFlag from "assets/icons/col_flag.png";
import LogoFroin from "assets/logos/LogoFroinBlanco.png";
import { Store } from "hooks/main_store";
import stickyHeaderStyles from "./stickyHeaderStyles";
import Model from "hooks/Model";
import useIsMobile from "hooks/custom/useIsMobile";
import Api from "utils/api";
import "./styles.css";
import { useWallet } from "services";
import { returnNumFormated } from "utils/validations";

interface HeaderProps {
  beforeToExit?: (callback: () => void) => () => void
}

function Header({beforeToExit}: HeaderProps) {
  const { t, i18n } = useTranslation();
  const { state } = useContext(Store);
  const { pathname } = useLocation();
  const navigateUrl = useNavigate();

  const navigate = (url: string) => {
    if (beforeToExit) {
      beforeToExit(() => navigateUrl(url))()
    } else {
      navigateUrl(url)
    }
  }

  const logOut = () => {
    Model.setData("dialog", {
      open: true,
      title: t("components.header.beforeContinue"),
      text: t("components.header.youWantLogout"),
      txtLeft: t("commons.cancel"),
      fnLeft: () => Model.setData("dialog", { open: false }),
      txtRight: t("commons.confirm"),
      fnRight: () => {
        Model.setData("dialog", {
          open: false,
        });
        Model.setData("loading", true);
        authService
          .signOut()
          .then(() => {
            Model.updateInfoUser(null);
            navigate("/");
            window.location.reload();
          })
          .catch((error) => {
            Model.updateAlerts({
              message: error?.message || error,
              variant: "error",
            });
          })
          .finally(() => Model.setData("loading", false));
      },
    });
  };

  const onChangeLanguage = () => {
    const currentLang = LocalStorage(false).getItem("defaultLanguage");
    const newLang = currentLang === "es" ? "en" : "es";
    i18n.changeLanguage(newLang, () => {
      LocalStorage(state.isIncognito).setItem("defaultLanguage", newLang);
    });
  };

  const getBalance = async () => {
    try {
      if (authService.currentUser()) {
        const userData = await databaseService.getDatabase(
          `users/${authService.currentUser().uid}`
        );

        if (!userData.exists()) {
          await Api.post("/wallet/initBalance");
        }

        Model.setData("balance", userData.val()?.balance || 0);
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    }
  };

  const firstLoad = async () => {
    try {
      Model.setData("opacity", 1);
      if (authService.currentUser()?.email) {
        Model.updateInfoUser(authService.currentUser());
      } else {
        Model.updateInfoUser(null);
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      Model.setData("opacity", 0.9);
      getBalance();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
  }, [pathname]);

  useEffect(() => {
    const unsubscribe = authService.onAuthStateChanged(() => {
      firstLoad();
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>
    {(state.infoUser?.email) && (
    <MobileHeader onChangeLanguage={onChangeLanguage} logOut={logOut} beforeToExit={beforeToExit} />
  )}
  <StickyHeader onChangeLanguage={onChangeLanguage} />
  </>;
}

const StickyHeader = ({onChangeLanguage}) => {
  const styles = stickyHeaderStyles();
  const { state } = useContext(Store);

  const [isMobile, setIsMobile] = useState(false);

  useIsMobile(setIsMobile);

  return (
    <div className={styles.container}>
      <Link underline="none" href="/">
        <img alt="FROIN" src={LogoFroin} className={styles.logo} />
      </Link>
      {isMobile ? <div /> : <>
        <Link underline="none" href="/profile-videos" className={styles.link}>
          <div className={styles.textLink}>Galería</div>
        </Link>
        {(state.infoUser?.email ? <Link underline="none" href="/ranking" className={styles.link}>
          <div className={styles.textLink}>Ranking</div>
        </Link> : <></>)}
      </>}
      
      {!state.infoUser?.email ? 
      <div className={styles.rowLogin}>
        <LanguageButton onChangeLanguage= {onChangeLanguage} />
      <button
        className={styles.loginButton}
        onClick={() => Model.setData("showAuth", true)}
      >
        <Person className={styles.loginIcon} />
        <span className={styles.loginLabel}>LOGIN</span>
      </button>
      </div>
       : <div className={styles.blankButton} />}
    </div>
  );
};

const MobileHeader = ({ onChangeLanguage, logOut, beforeToExit }) => {
  const { t } = useTranslation();
  const { state } = useContext(Store);
  const location = useLocation();
  const navigateUrl = useNavigate();

  const navigate = (url: string) => {
    if (beforeToExit) {
      beforeToExit(() => navigateUrl(url))()
    } else {
      navigateUrl(url)
    }
  }

  const [openDrawer, setOpenDrawer] = useState(false);
  const { currentBalance } = useWallet()

  const returnOptionsActions = (names = []) => {
    const options = [];

    names.forEach((name) => {
      let object = {};
      switch (name) {
        case "home":
          object = {
            icon: <Home />,
            title: t("components.header.home"),
            action: () => navigate("/"),
          };
          break;
        case "receive":
          object = {
            icon: <SaveAlt />,
            title: t("components.header.receive"),
            action: () => navigate("/receive"),
          };
          break;
        case "history":
          object = {
            icon: <History />,
            title: t("components.header.history"),
            action: () => navigate("/history"),
          };
          break;
        case "send":
          object = {
            icon: <Send />,
            title: t("components.header.send"),
            action: () => navigate("/send"),
          };
          break;
        case "ranking":
          object = {
            icon: <EmojiEvents />,
            title: t("components.header.ranking"),
            action: () => navigate("/ranking"),
          };
          break;
          case "profile-videos":
            object = {
              icon: <PlayCircleOutline />,
              title: "Galería",
              action: () => navigate("/profile-videos"),
            };
            break;
        default:
          break;
      }
      options.push(object);
    });

    return options;
  };

  const returnActions = () => {
    let actions = [];

    if (location.pathname.includes("/send")) {
      actions = returnOptionsActions(["home", "receive", "ranking", "profile-videos", "history"]);
    } else if (location.pathname.includes("/receive")) {
      actions = returnOptionsActions(["home", "send", "ranking", "profile-videos", "history"]);
    } else if (location.pathname.includes("/history")) {
      actions = returnOptionsActions(["home", "send", "receive", "ranking", "profile-videos"]);
    } else if (location.pathname.includes("/ranking")) {
      actions = returnOptionsActions(["home", "send", "receive", "profile-videos", "history"]);
    } else if (
      location.pathname.includes("/detail-video") ||
      location.pathname.includes("/profile-videos")
    ) {
      actions = returnOptionsActions([
        "home",
        "send",
        "receive",
        "ranking",
        "history",
      ]);
    } else {
      actions = returnOptionsActions(["send", "receive", "ranking", "profile-videos", "history"]);
    }

    return actions.map((item) => (
      <SpeedDialAction
        key={item.title}
        icon={item.icon}
        tooltipTitle={item.title}
        tooltipOpen
        onClick={item.action}
      />
    ));
  };

  return (
    <>
      <Backdrop
        open={openDrawer}
        className="backdrop-header-container"
        onClick={() => setOpenDrawer(false)}
      />
      {state.infoUser?.email ? (
        <div className="header-container">
          {openDrawer ? (
            <>
              <button className="logout-btn" onClick={logOut}>
                <span className="email">{state.infoUser?.email}</span>
                <span className="logout">{t("components.header.logOut")}</span>
              </button>
              <div className="balance-txt">
                Saldo $FROIN {returnNumFormated(currentBalance || 0)}
              </div>
            </>
          ) : (
            <LanguageButton onChangeLanguage= {onChangeLanguage} />
          )}

          <SpeedDial
            ariaLabel="Menu"
            icon={<SpeedDialIcon icon={<Menu />} openIcon={<Close />} />}
            onClick={() => setOpenDrawer((ov) => !ov)}
            open={openDrawer}
            className="menu-container"
            direction="down"
          >
            {openDrawer ? returnActions() : null}
          </SpeedDial>
        </div>
      ) : (
        <div className="header-container">
          <SpeedDial
            ariaLabel="Menu"
            icon={<SpeedDialIcon icon={<Menu />} openIcon={<Close />} />}
            onClick={() => Model.setData("showAuth", true)}
            className="menu-container"
            direction="down"
          />
        </div>
      )}
    </>
  );
};

const LanguageButton = ({ onChangeLanguage  }) => {
  // const { i18n } = useTranslation();

  return <></>

  // return (
  //   <button className="language-btn" onClick={onChangeLanguage}>
  //     <img
  //       alt="Language"
  //       src={i18n.language === "en" ? UsaFlag : ColFlag}
  //     />
  //   </button>
  // );
};

export default Header;
