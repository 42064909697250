import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  container: {
    ...Commons.flexRow,
    gap: 12,
    marginTop: 30,
    [Commons.smallQuery]: {
      flexDirection: "column",
      gap: 6,
    },
  },
  backBuyBtn: {
    ...Commons.defaultButton,
    ...Commons.flexCenter,
    backgroundColor: colors.white,
    borderRadius: "50%",
    minWidth: 50,
    maxWidth: 50,
    maxHeight: 50,
    minHeight: 50,
    margin: "12px 0",
    transition: "all 0.3s",
    border: "2px solid transparent",
    color: colors.black,
    fontSize: 18,
    position: "relative",
    "&:hover": {
      borderColor: colors.white,
      color: colors.white,
    },
    "&.colombia:hover": {
      backgroundColor: colors.orange,
    },
    "&.international:hover": {
      backgroundColor: colors.secondary,
    },
    "&.crypto:hover": {
      backgroundColor: colors.black,
    },
  },
  buyBtn: {
    ...Commons.defaultButton,
    backgroundColor: colors.white,
    width: "100%",
    border: "2px solid transparent",
    borderRadius: 30,
    padding: "12px 6px",
    textAlign: "center",
    color: colors.black,
    fontSize: 18,
    fontWeight: 600,
    transition: "all 0.3s",
    margin: "12px 0",
    position: "relative",
    whiteSpace: "nowrap",
    "&:hover": {
      borderColor: colors.white,
      color: colors.white,
    },
    "&.colombia:hover": {
      backgroundColor: colors.orange,
    },
    "&.international:hover": {
      backgroundColor: colors.secondary,
    },
    "&.crypto:hover": {
      backgroundColor: colors.black,
    },
  },
  infoPrice: {
    position: "absolute",
    top: -18,
    left: "50%",
    transform: "translateX(-50%)",
    fontSize: 12,
    backgroundColor: colors.black,
    color: colors.white,
    borderRadius: 18,
    whiteSpace: "nowrap",
    padding: "6px 12px",
    border: "2px solid white",
    [Commons.smallQuery]: {
      top: "unset",
      bottom: -18,
    },
  },
  rowLoading: {
    ...Commons.flexRow,
    gap: 18,
    marginTop: 30,
    [Commons.smallQuery]: {
      flexDirection: "column",
      gap: 6,
    },
  },
  spinnerRowLoading: {
    lineHeight: "32px",
    position: "relative",
    "& svg": {
      color: colors.orange,
    },
    "& span.timer": {
      color: colors.orange,
      [Commons.smallQuery]: {
        ...Commons.centerVertical,
        position: "absolute",
        left: -0,
      },
    },
    [Commons.smallQuery]: {
      paddingLeft: 60,
    },
  },
  linkRowLoading: {
    ...Commons.defaultButton,
    backgroundColor: colors.orange,
    color: colors.white,
    whiteSpace: "nowrap",
    borderRadius: 30,
    padding: "12px 18px",
    textDecoration: "none",
    fontWeight: "600",
    fontSize: 18,
    boxSizing: "border-box",
    [Commons.smallQuery]: {
      width: "100%",
    },
  },
  infoRowLoading: {
    color: colors.white,
    fontSize: 16,
    textAlign: "left",
    "& span": {
      fontWeight: 600,
      color: colors.orange,
      fontSize: 18,
    },
  },
  containerPayPal: {
    backgroundColor: colors.white,
    marginTop: 30,
    borderRadius: 30,
    overflow: "hidden",
    "& div": {
      boxSizing: "border-box",
      padding: 30,
      borderBox: "",
    },
  },
});

export default styles;
