import * as React from "react";
import { useEffect } from "react";
import { Grid } from "@mui/material";
import ReactPlayer from "react-player/youtube";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useParallax } from "react-scroll-parallax";
import * as moment from "moment";

import pageStyles from "./ProfileVideosStyles";
import HeaderImage from "assets/images/header_profile_videos.png";
import ProfilePlaceholder from "assets/icons/profile_placeholder.svg";
import PlayCircleImage from "assets/icons/play_circle.svg";
import { Layout, ElementsParallax } from "components";
import { useChannel } from "services";
import {VideoModels} from "models";

function ProfileVideosScreen() {
  const styles = pageStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  
  const channel = searchParams.get("channel") || 'froin'

  const { loading, channelInfo, recommendedVideos } = useChannel({channel})

  const { ref: refBanner } = useParallax<HTMLImageElement>({
    speed: 200,
    scale: [1, 2],
    translateY: [-6, 6],
    targetElement: document.getElementById("root"),
  });

  const renderVideos = () => recommendedVideos.map(({id, url, imageUrl, created}: VideoModels.VideoInfoProps) => (
    <Grid
      item
      className={styles.itemPlayer}
      onClick={() => navigate(`/detail-video?id=${id}`)}
      key={id}
    >
      {imageUrl ? <img src={imageUrl} className={styles.imageVideo} alt="Video" /> : <ReactPlayer
        url={url}
        light
        width="100%"
        height="100%"
        playing={false}
        playIcon={<></>}
      />}
      <div className={styles.overlayPlayer}>
        <img
          alt="Play"
          className={styles.iconPlayer}
          src={PlayCircleImage}
        />
      </div>
      {moment().diff(moment(created, 'x'), 'd') < 7 ? <div className={styles.newBadge}>Recien agregado</div> : <></>}
    </Grid>
  ))

  useEffect(() => {
    if (!loading && !channel) {
      navigate('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <Layout withBg>
      <ElementsParallax variant="leftBg" />
      <ElementsParallax variant="rightBg" />
      <div className={styles.headerContainer}>
        <div
          className={styles.headerImage}
          style={{ backgroundImage: `url(${HeaderImage})` }}
          ref={refBanner}
        />
      </div>
      <div className={styles.containerProfileImage}>
        <img
          alt="profile"
          className={[styles.profileImage, channelInfo?.image ? styles.bgWhite : ''].join(' ')}
          src={channelInfo?.image || ProfilePlaceholder}
        />
      </div>
      <h3 className={styles.nameProfile}>{channelInfo?.name}</h3>
      <p className={styles.descriptionProfile}>
      {channelInfo?.description}
      </p>
      <p className={styles.label}>Contenido publicado</p>
      <Grid container className={styles.containerListPlayer}>
        {renderVideos()}
      </Grid>
    </Layout>
  );
}

export default ProfileVideosScreen;
