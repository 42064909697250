import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  input: {
    border: `2px solid ${colors.orange}`,
    backgroundColor: colors.white,
    outline: "none",
    borderRadius: 24,
    padding: "12px 24px",
    width: "100%",
    boxSizing: "border-box",
    margin: "18px 0 0 0",
    fontSize: 24,
    textAlign: "center",

    "&[disabled]": {
      backgroundColor: colors.grayBlack,
      opacity: 0.75,
    },

    [Commons.midQuery]: {
      fontSize: 18,
    },
  },
});

export default styles;
