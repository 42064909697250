import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  container2: {
    position: "relative",
    backgroundColor: colors.black,
    width: "100%",
    overflow: "hidden",
    "& canvas": {
      width: "100% !important",
      height: "calc((100vw - 150px) * 3.8775) !important",
      maxWidth: 1200,
      maxHeight: 4653,
      margin: "0 auto",
    },
    "& .react-pdf__Page__annotations.annotationLayer": {
      display: "none",
    },
    "& .react-pdf__Page": {
      backgroundColor: "transparent !important",
    },
    [Commons.midQuery]: {
      "& canvas": {
        height: "calc((100vw - 30px) * 3.8775) !important",
      },
    }
  },
  container: {
    position: "relative",
    backgroundColor: colors.black,
    width: "100%",
    overflow: "hidden",
    "& canvas": {
      width: "100vw !important",
      height: "129vw !important",
      maxWidth: 800,
      maxHeight: 1035,
      margin: "0 auto",
    },
    "& .react-pdf__Page__annotations.annotationLayer": {
      display: "none",
    },
    "& .react-pdf__Page": {
      backgroundColor: "transparent !important",
    },
  },
  cover: {
    position: "absolute",
    width: "100%",
    backgroundColor: colors.white,
    top: 0,
    left: 0,
    opacity: 0,
  },
});

export default styles;
