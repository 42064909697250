import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { verifyEmail } from "utils/validations";
import pageStyles from "./styles/SendFormStyles";
import { Store } from "hooks/main_store";
import { TextInput } from "components";
import Model from "hooks/Model";
import Api from "utils/api";

function SendForm() {
  const { t } = useTranslation();
  const styles = pageStyles();
  const urlParams = new URLSearchParams(window.location.search);

  const {
    state: { infoUser, balance },
  } = useContext(Store);

  const [email, setEmail] = useState(urlParams.get("receiver") || "");
  const [quantity, setQuantity] = useState("");

  const onConfirmSend = async () => {
    try {
      Model.setData("dialog", { open: false });
      Model.setData("loading", true);
      const response = await Api.post("/wallet/sendTokens", {
        quantity: parseInt(quantity?.replace(/\D/g, "") || "0"),
        email: email.toLowerCase().replace(/\s/g, ""),
      });

      if (response.ok) {
        setEmail(urlParams.get("receiver") || "");
        setQuantity("");
        Model.updateAlerts({
          message: t("pages.send.sendForm.tokensSent"),
          variant: "success",
        });
      } else {
        throw new Error(response.data as string);
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onSend = async () => {
    try {
      if (!email || !quantity) {
        throw new Error(t("pages.send.sendForm.enterAllData"));
      } else if (quantity === "0") {
        throw new Error(t("pages.send.sendForm.amountGreater"));
      } else if (email.toLowerCase() === infoUser?.email.toLowerCase()) {
        throw new Error(t("pages.send.sendForm.youCannotSend"));
      } else if (balance < parseInt(quantity?.replace(/\D/g, "") || "0")) {
        throw new Error(t("pages.send.sendForm.noHaveEnough"));
      } else if (verifyEmail(email)) {
        Model.setData("dialog", {
          open: true,
          title: t("pages.send.sendForm.sendFroin"),
          text: t("pages.send.sendForm.doYouAgreeSend", { quantity, email }),
          txtLeft: t("commons.cancel"),
          fnLeft: () => Model.setData("dialog", { open: false }),
          txtRight: t("commons.confirm"),
          fnRight: onConfirmSend,
        });
      } else {
        throw new Error(t("pages.send.sendForm.enterValidEmail"));
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    }
  };

  useEffect(() => {
    Model.setData("loading", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h2 className={styles.title}>{t("pages.send.sendForm.sendFroin")}</h2>
      <p className={styles.description}>
        {t("pages.send.sendForm.ourSendSystem")}{" "}
        <b>{t("pages.send.sendForm.easyFast")}</b>,{" "}
        {t("pages.send.sendForm.theOnlyThing")}{" "}
        <a href="mailto:help@froin.co">
          {t("pages.send.sendForm.support")}
        </a>
        .
      </p>

      <p className={styles.labelInput}>
        {t("pages.send.sendForm.amountTokens")}
      </p>
      <TextInput
        placeholder={t("pages.send.sendForm.howMany")}
        value={quantity}
        onChange={setQuantity}
        format="currency"
        maxLength={10}
        inputMode="numeric"
        customClassName={styles.input}
      />
      <p className={styles.labelInput}>
        {t("pages.send.sendForm.recipientEmail")}
      </p>
      <TextInput
        placeholder="joe@example.com"
        value={email}
        onChange={setEmail}
        type="email"
        customClassName={styles.input}
        disabled={!!urlParams.get("receiver")}
      />
      <button
        className={[styles.button, !email || !quantity ? "disabled" : ""].join(
          " "
        )}
        onClick={onSend}
      >
        {t("pages.send.sendForm.sendFroin")}
      </button>
    </>
  );
}

export default SendForm;
