import * as React from "react";
import { returnFormatNumber } from "utils/validations";
import pageStyles from "./styles";

interface TextInputProps extends React.ComponentProps<'input'> {
  format?: string;
  setValue?: () => void;
  onChange?: (value?: any) => void;
  customClassName?: string;
}

function TextInput({ format, setValue, onChange, customClassName, ...props }: TextInputProps) {
  const styles = pageStyles();

  const onChangeText = (event) => {
    if (format === "currency") {
      onChange(returnFormatNumber(event?.target?.value?.replace(/\D/g, "")));
    } else {
      onChange(event?.target?.value);
    }
  };

  return (
    <input
      {...props}
      className={[styles.input, customClassName || ""].join(" ")}
      onChange={onChangeText}
    />
  );
}

export default TextInput;
