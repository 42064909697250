import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  container: {
    ...Commons.flexCenter,
    flexDirection: "column",
    width: "100vw",
    height: "100vh",
    backgroundColor: colors.black,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 999
  },
  icon: {
    color: colors.white,
    fontSize: "72px !important",
  },
  message: {
    width: "90%",
    maxWidth: 420,
    color: colors.white,
    fontSize: 24,
    textAlign: "center",
    margin: "12px 0 42px 0",
  },
  logo: {
    height: 78,
  },
});

export default styles;
