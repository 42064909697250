import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import pageStyles from "./styles/AccessAccountStyles";
import { Layout } from "components";
import Model from "hooks/Model";

function AccessAccountScreen() {
  const { t } = useTranslation();
  const styles = pageStyles();
  const navigation = useNavigate();

  useEffect(() => {
    Model.setData("loading", false);
  }, []);

  return (
    <Layout withBg>
      <p className={styles.title}>
        {t("pages.home.accessAccount.loggedIntoAccount")}
      </p>
      <p className={styles.description}>
        {t("pages.home.accessAccount.thankYou")}
      </p>
      <button className={styles.button} onClick={() => navigation("/")}>
        {t("pages.home.accessAccount.backHome")}
      </button>
    </Layout>
  );
}

export default AccessAccountScreen;
