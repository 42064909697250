import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  title: {
    margin: 0,
    color: colors.orange,
    fontSize: 42,
    fontWeight: 600,
    [Commons.smallQuery]: {
      fontSize: 30,
    },
  },
  description: {
    color: colors.white,
    fontSize: 18,
    textAlign: "left",
    margin: "12px 0 60px 0",
    whiteSpace: "break-spaces",
    "& a": {
      color: colors.orange,
      textDecoration: "none",
    },
  },
  noItemsLabel: {
    textAlign: "center",
    color: colors.white,
    fontSize: 24,
    maxWidth: 780,
    boxSizing: "border-box",
    padding: "60px 18px",
    [Commons.smallQuery]: {
      fontSize: 18,
    },
  },
  itemList: {
    ...Commons.flexRow,
    backgroundColor: colors.white,
    border: `6px solid ${colors.secondary}`,
    borderRadius: 18,
    marginBottom: 30,
    padding: "30px 18px",
    overflow: "hidden",
    boxSizing: "border-box",
    width: "100%",
    maxWidth: 780,
    transition: "all 0.3s",
    "&:hover": {
      borderColor: colors.orange,
    },
    [Commons.smallQuery]: {
      marginBottom: 18,
      padding: "18px 12px",
    },
  },
  quantityItemList: {
    fontWeight: 600,
    fontSize: 24,
    textAlign: "center",
    lineHeight: "18px",
    "& span": {
      fontSize: 14,
    },
  },
  rowItemList: {
    margin: "0 18px",
    flex: 1,
  },
  personItemList: {
    margin: "0 0 6px 0",
    fontSize: 18,
    wordBreak: "break-all",
    [Commons.smallQuery]: {
      fontSize: 16,
    },
  },
  dateItemList: {
    margin: 0,
    fontSize: 14,
    "& svg": {
      position: "relative",
      top: 6,
      marginTop: -6,
    },
    [Commons.smallQuery]: {
      fontSize: 12,
    },
  },
  iconItemList: {
    color: colors.secondary,
    fontSize: 36,
    [Commons.smallQuery]: {
      fontSize: 24,
    },
  },
  icon2ItemList: {
    color: colors.orange,
    fontSize: 36,
    [Commons.smallQuery]: {
      fontSize: 24,
    },
  },
  icon3ItemList: {
    color: colors.primary,
    fontSize: '36px !important',
    [Commons.smallQuery]: {
      fontSize: '24px !important',
    },
  },
  rowPagination: {
    ...Commons.flexRow,
    justifyContent: "space-between",
    width: "100%",
    maxWidth: 780,
    marginBottom: 30,
  },
  leftContRowPagination: {
    ...Commons.defaultButton,
    ...Commons.flexColumn,
    alignItems: "flex-start",
    textAlign: "left",
  },
  rightContRowPagination: {
    ...Commons.defaultButton,
    ...Commons.flexColumn,
    alignItems: "flex-end",
    textAlign: "right",
  },
  iconRowPagination: {
    ...Commons.flexCenter,
    backgroundColor: colors.secondary,
    width: 30,
    height: 30,
    borderRadius: "50%",
    color: colors.white,
  },
  labelRowPagination: {
    color: colors.white,
    fontSize: 12,
    marginTop: 6,
  },
  countRowPagination: {
    color: colors.white,
    fontSize: 18,
  },
  paymentIcon: {
    height: 30,
    margin: "0 18px",
    [Commons.smallQuery]: {
      position: "absolute",
      bottom: 6,
      right: 12,
      height: 18,
      margin: 0,
    },
  },
});

export default styles;
