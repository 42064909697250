import * as React from "react";
import { useContext } from "react";

import pageStyles from "./styles";
import { Store } from "hooks/main_store";

function Footer() {
  const styles = pageStyles();
  const { state } = useContext(Store);

  return (
    <div className={styles.container}>
      FROIN © 2024 v{state.version}
    </div>
  );
}

export default Footer;
