import * as React from "react";
import { Layout, ElementsParallax } from "components";
import SendForm from "./SendForm";

function SendScreen() {
  return (
    <Layout withBg>
      <ElementsParallax variant="leftBg" />
      <ElementsParallax variant="rightBg" />
      <SendForm />
    </Layout>
  );
}

export default SendScreen;
