import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Backdrop } from "@mui/material";
import { detectIncognito } from "detectincognitojs";
import { Done } from "@mui/icons-material";

import Logocrom from "assets/logos/froin-3d.svg";
import pageStyles from "./styles";
import { Store, StoreProps } from "hooks/main_store";
import { TextInput } from "components";
import Model from "hooks/Model";
import { returnCurrentLink, verifyEmail } from "utils/validations";
import { authService } from "utils/firebase";
import LocalStorage from "utils/localStorage";

function AuthModal() {
  const styles = pageStyles();
  const { t } = useTranslation();

  const { state } = useContext<StoreProps>(Store);
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [requestEmail, setRequestEmail] = useState(false);

  const onSubmit = async () => {
    try {
      Model.setData("loading", true);
      const formatedEmail = email.replace(/\s/g, '')

      if (!acceptTerms && !requestEmail) {
        throw new Error(`ERROR: ${t("components.authModal.acceptTerms")}`);
      } else if (!formatedEmail) {
        throw new Error(`ERROR: ${t("pages.home.buyHome.enterValidEmail")}`);
      } else if (!verifyEmail(formatedEmail)) {
        throw new Error(`ERROR: ${t("pages.home.buyHome.formatInvalid")}`);
      } else if (requestEmail) {
        await firstLoad(formatedEmail)
      } else {
        const result = await detectIncognito();

        await authService.sendSignInLinkToEmail(formatedEmail.toLocaleLowerCase());
        LocalStorage(result.isPrivate).setItem("emailForSignInCrypto", formatedEmail);

        setEmailSent(true)
        setEmail(formatedEmail)
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const onAccept = () => {
    setAcceptTerms(prev => !prev)
  }

  const renderContent = () => {
    if (emailSent) {
      return <p className={styles.message}>
        {t("pages.home.buyHome.checkEmail")} (<span>{email}</span>){" "}
        {t("pages.home.buyHome.andFollow")}
      </p>
    }

    return <>
      <p className={styles.message}>{requestEmail ? 'Por tu seguridad, vuelve a ingresar tu correo para ingresar' : 'Ya sea tu primera vez o si ya tienes cuenta, ingresa tu correo para acceder a tu perfil'}</p>
      <TextInput
        value={email}
        onChange={setEmail}
        placeholder={"Tu correo"}
      />
      <button className={styles.submit} onClick={onSubmit}>{requestEmail ? 'Verificar acceso' : 'Enviar email de acceso'}</button>
      {!requestEmail && <div className={styles.row}>
        <button className={[styles.check, acceptTerms ? styles.checked : ''].join(' ')} onClick={onAccept}>
          <Done className={styles.iconCheck} />
        </button>
        <p className={styles.terms}>Acepto los <a href={returnCurrentLink("/legal?document=terms")} target="_blank" className={styles.linkTerms} rel="noreferrer">Términos &amp; Condiciones</a> y la <a  href={returnCurrentLink("/legal?document=privacy")} target="_blank" className={styles.linkTerms} rel="noreferrer">Política de Privacidad</a></p>
      </div>}
    </>
  }

  const firstLoad = async (emailString?: string) => {
    let avoidHideModal = false

    try {
      Model.setData("loading", true);

      if (authService.isSignInWithEmailLink(window.location.href)) {
        const result = await detectIncognito();
        let email = emailString || LocalStorage(result.isPrivate).getItem(
          "emailForSignInCrypto"
        );

        if (!email) {
          Model.setData("showAuth", true);
          setRequestEmail(true)
          avoidHideModal = true
          
          return Model.updateAlerts({
            message: t("pages.home.buyHome.pleaseEnterEmail"),
            variant: "warning",
          });
        }

        const userInfo = await authService.signInWithEmailLink(
          email,
          window.location.href
        );

        LocalStorage(result.isPrivate).removeItem("emailForSignInCrypto");
        Model.updateInfoUser(userInfo.user);
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
      if (!avoidHideModal) {
        Model.setData("showAuth", false);
      }
    }
  };

  useEffect(() => {
    const unsubscribe = authService.onAuthStateChanged((user) => {
      if (!user && window.location.href.includes("oobCode")) {
        firstLoad();
      }
    });

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.showAuth && state.infoUser?.uid) {
      Model.setData("showAuth", false);
      Model.updateAlerts({
        message: 'Tu sesión ya está abierta :)',
        variant: "warning",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.showAuth, state.infoUser?.uid]);

  if (!state.showAuth || !!state.infoUser?.uid) {
    return <></>
  }

  return (
    <>
      <div className={styles.container}>
        <Backdrop
          open={state.showAuth}
          className={styles.backdrop}
          onClick={() => Model.setData("showAuth", false)}
        />
        <div className={styles.card}>
          <img src={Logocrom} alt="FROIN" className={styles.logo} />
          {renderContent()}
          
        </div>
      </div>
    </>
  );
}

export default AuthModal;
