import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import ReactYoutubePlayer from "react-player/youtube";
import ReactSoundcloudPlayer from "react-player/soundcloud";
import ReactMixcloudPlayer from "react-player/mixcloud";
import { Grid } from "@mui/material";
import { InfoOutlined, PlayCircle, EmojiEvents, Visibility, Star } from "@mui/icons-material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Layout, SuggestedVideos, Tooltip, TwitchPlayer } from "components";
import Logo from "assets/logos/IsoFroinBlanco.png";
import { returnApiError, returnNumFormated } from "utils/validations";
import { firestoreService } from "utils/firebase";
import pageStyles from "./DetailVideoStyles";
import SadImage from "assets/icons/sad.svg";
import { Store } from "hooks/main_store";
import { VideoModels } from "models";
import Model from "hooks/Model";
import Api from "utils/api";
import { useRanking } from "services";

const PROGRESS_INTERVAL = 25000

interface OwnerInfoProps {
  image?: string;
  name?: string;
}

function DetailVideoScreen() {
  const { state } = useContext(Store);
  const styles = pageStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { settings } = useRanking({});

  const [rewards, setRewards] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [loading, setLoading] = useState(true);
  const [played, setPlayed] = useState(false);
  const [firstPlay, setFirstPlay] = useState(false);
  const [videoInfo, setVideoInfo] = useState<VideoModels.VideoInfoProps>({});
  const [ownerInfo, setOwnerInfo] = useState<OwnerInfoProps>({});
  const [recommendedVideos, setRecommendedVideos] = useState([]);
  const [videoLoadStatus, setVideoLoadStatus] = useState('');

  const updateRewards = () => {
    if (played) {
      setRewards((oldValue) => {
        const newValue = oldValue + 25

        if (videoInfo?.limit > 0 && videoInfo.limit < newValue) {
          return videoInfo?.limit
        }

        return newValue
      });
    }
  };

  const onReplace = async () => {
    try {
      Model.setData("loading", true);
      Model.setData("dialog", {
        open: false,
      });

      const response = await Api.post("/ranking/vwin/replaceCurrentVideo", {id: searchParams.get("id")});

      if (response.ok) {
        if (response.data === 'available') {
          setPlayed(true);
    
          if (!firstPlay) {
            setRewards(1);
          }
      
          setFirstPlay(true);
        } else if (response.data === 'cant-play') {
          setVideoLoadStatus('cant-play')
          setPlayed(false);
        } else {
          throw new Error('Error desconocido');
        }
      } else {
        throw new Error(returnApiError(response));
      }
    } catch (error) {
      setPlayed(false);
      setVideoLoadStatus('error')
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
  };

  const confirmReplace = () => {
  
    Model.setData("dialog", {
      open: true,
      title: 'Ups ... no puedes continuar',
      text: 'Actualmente estás visualizando otro contenido en este momento. Por seguridad solo puedes ver un contenido a la vez para generar recompensas. ¿Quieres ver este nuevo contenido e inhabilitar el anterior por 24 horas?',
      txtLeft: t("commons.cancel"),
      fnLeft: () => {
        Model.setData("dialog", { open: false })
        setRewards(0);
        setLoading(true)
        setTimeout(() => {
          setLoading(false)
        }, 500);
      },
      txtRight: t("commons.confirm"),
      fnRight: onReplace,
    });
  };

  const liveAlert = () => {
    Model.setData("dialog", {
      open: true,
      title: '🚨 NO PAUSES LA TRANSMISIÓN 🚨',
      text: 'Te recomendamos que no vayas a pausar en ningún momento la transmisión porque puede que se reinicien tus FOINTS y debas volver a acumular desde cero. Si ya deseas terminar no olvides presionar en RECLAMAR.',
      txtRight: 'Entendido',
      fnRight: () => {
        Model.setData("dialog", { open: false })
      },
    });
  };

  const onPlay = useCallback(async () => {
    try {
      Model.setData("loading", true);

      if (state.infoUser?.email) {
        const response = await Api.post("/ranking/vwin/verifyBeforeWatch", {id: searchParams.get("id")});
  
        if (response.ok) {
          if (response.data === 'available') {
            setPlayed(true);
      
            if (!firstPlay) {
              setRewards(1);

              if (videoInfo?.provider === 'twitch') {
                liveAlert()
              }
            }
        
            setFirstPlay(true);
          } else if (response.data === 'has-other') {
            confirmReplace()
          } else if (response.data === 'cant-play') {
            setVideoLoadStatus('cant-play')
            setPlayed(false);
          } else {
            throw new Error('Error desconocido');
          }
        } else {
          throw new Error(returnApiError(response));
        }
      } else {
        throw new Error('Debes iniciar sesión para participar');
      }

    } catch (error) {
      setPlayed(false);
      setVideoLoadStatus(error?.message === 'Debes iniciar sesión para participar' ? 'need-auth' : 'error')
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
    }
    // eslint-disable-next-line
  }, [state.infoUser, firstPlay, videoInfo]);

  const getRecommendedVideos = async (owner) => {
    try {
      if (owner) {
        const ownerInfoResponse = (
          await firestoreService.getDoc("channels", owner)
        ).data();
        const recommendedVideosResponse = [];
        const getVideosResponse = await firestoreService.getQuery("videos", [
          { label: "owner", condition: "==", value: owner },
        ]);

        getVideosResponse.forEach((video) => {
          if (searchParams.get("id") !== video.id && video.data()?.hide !== true) {
            recommendedVideosResponse.push({
              ...(video.data() || {}),
              id: video.id,
            })
          }
        }
        );

        setOwnerInfo(ownerInfoResponse);
        setRecommendedVideos(recommendedVideosResponse.sort((a,b) => (b?.order || 0) - (a?.order || 0)));
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    }
  };

  const saveRewards = async () => {
    try {
      Model.setData("dialog", { open: false })
      Model.setData("loading", true);
      const response = await Api.post("/ranking/vwin/saveScore", {foints: rewards, id: searchParams.get("id")});

      if (response.ok && response.data === 'saved') {
        setPlayed(false);
        setRewards(0)
        setLoading(true)

        Model.updateAlerts({
          message: 'FOINTS acumulados éxistosamente',
          variant: "success",
        });
      } else if (response.ok && response.data === 'has-other') {
        Model.updateAlerts({
          message: 'Actualmente estas viendo otro contenido y no será posible guardar estos FOINTS',
          variant: "error",
        });
      } else if (response.ok && response.data === 'ranking-finished') {
        Model.updateAlerts({
          message: 'Aún no puedes acumular FOINTS para el ranking porque ya ha finalizado',
          variant: "error",
        });
      } else {
        throw new Error(returnApiError(response));
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    }
    finally {
      Model.setData("loading", false);
      setTimeout(() => {
        setLoading(false)
      }, 1000);
    }
  }

  const onRequestRewards = () => {
    if (rewards > 0) {
      if (settings.finished) {
        Model.setData("dialog", {
          open: true,
          title: "Ranking finalizado",
          text: 'Aún no puedes acumular FOINTS para el ranking porque ya ha finalizado, pronto enviaremos la notificación para la próxima fecha de inicio y la descripción del premio.',
          txtRight: "Ok",
          fnRight: () => Model.setData("dialog", { open: false }),
        });
      } else {
        Model.setData("dialog", {
          open: true,
          title: t("components.header.beforeContinue"),
          text: '¿Estas de acuerdo en reclamar estas recompensas por hoy? Recuerda que solo puedes reproducir cada contenido una vez al día.',
          txtLeft: t("commons.cancel"),
          fnLeft: () => Model.setData("dialog", { open: false }),
          txtRight: t("commons.confirm"),
          fnRight: saveRewards,
        });
      }
    } else {
      Model.updateAlerts({
        message: 'Debes generar más FOINTS, solo reproduce el contenido del artista',
        variant: "error",
      });
    }
  }

  const renderContentVideo = () => {
    switch (videoLoadStatus || videoInfo?.provider) {
      case 'mixcloud':
        return <ReactMixcloudPlayer
          url={videoInfo?.url}
          light
          width="100%"
          height="100%"
          progressInterval={PROGRESS_INTERVAL}
          onProgress={updateRewards}
          onPause={() => setPlayed(false)}
          onEnded={() => setPlayed(false)}
          onError={() => setPlayed(false)}
          onPlay={onPlay}
          playIcon={<PlayCircle className={styles.iconPlayer} />}
        />
      case 'soundcloud':
        return <ReactSoundcloudPlayer
          url={videoInfo?.url}
          light
          width="100%"
          height="100%"
          progressInterval={PROGRESS_INTERVAL}
          onProgress={updateRewards}
          onPause={() => setPlayed(false)}
          onEnded={() => setPlayed(false)}
          onError={() => setPlayed(false)}
          onPlay={onPlay}
          playIcon={<PlayCircle className={styles.iconPlayer} />}
        />
      case 'youtube':
        return <ReactYoutubePlayer
          url={videoInfo?.url}
          config={{
            playerVars: { showinfo: 0, controls: 1, autoplay: 1 },
          }}
          light
          width="100%"
          height="100%"
          progressInterval={PROGRESS_INTERVAL}
          onProgress={updateRewards}
          onPause={() => setPlayed(false)}
          onEnded={() => setPlayed(false)}
          onError={() => setPlayed(false)}
          onPlay={onPlay}
          playIcon={<PlayCircle className={styles.iconPlayer} />}
        />
      case 'twitch':
        return <TwitchPlayer
          url={videoInfo?.url}
          width="100%"
          height="100%"
          progressInterval={PROGRESS_INTERVAL}
          onProgress={updateRewards}
          onPause={() => setPlayed(false)}
          onEnded={() => setPlayed(false)}
          onError={() => setPlayed(false)}
          onPlay={onPlay}
        />
      case 'need-auth':
        return <div className={styles.contentErrorVideo}>
          <img alt="Error load video" className={styles.errorVideoIcon} src={SadImage} />
          <p className={styles.errorVideoText}>Antes ver este contenido, y acumular FOINTS para el ranking de premios, debes haber iniciado sesión en tu cuenta. <span onClick={() => Model.setData("showAuth", true)}>Hazlo aquí.</span></p>
        </div>
      case 'cant-play':
        return <div className={styles.contentErrorVideo}>
          <img alt="Error load video" className={styles.errorVideoIcon} src={SadImage} />
          <p className={styles.errorVideoText}>Ya has visualizado este contenido en las útlimas 24 horas, vuelve luego para  reproducirlo de nuevo.</p>
        </div>
      default:
        return <div className={styles.contentErrorVideo}>
          <img alt="Error load video" className={styles.errorVideoIcon} src={SadImage} />
          <p className={styles.errorVideoText}>Ups ... Hubo un problema y no podemos cargar el video, por favor revisa que no tienes otra sesión abierta. Si el problema persiste y eres el dueño de este video por favor contáctanos.</p>
        </div>
    }
  }

  const firstLoad = async (videoId) => {
    try {
      setLoading(true)
      Model.setData("loading", true);
      if (searchParams.get("id")) {
        const videoInfoResponse = (
          await firestoreService.getDoc("videos", videoId)
        ).data();

        if (videoInfoResponse) {
          setVideoInfo(videoInfoResponse);
          await getRecommendedVideos(videoInfoResponse?.owner);
        } else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      setLoading(false)
      Model.setData("loading", false);
    }
  };

  const beforeToExit = (callback: () => void) => () => {
    if (rewards > 0) {
      Model.setData("dialog", {
        open: true,
        title: "No olvides reclamar tu puntaje",
        text: "Aún tienes recompensas por reclamar, ¿estas de acuerdo en salir? Tus FOINTS no se recuperarán y tendrás que ver de nuevo el contenido.",
        txtLeft: t("commons.cancel"),
        fnLeft: () => Model.setData("dialog", { open: false }),
        txtRight: t("commons.confirm"),
        fnRight: () => {
          Model.setData("dialog", {
            open: false,
          });
          Model.setData("loading", true);
          callback()
        },
      });
    } else {
      callback()
    }
  };

  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    if (rewards > 0) {
      window.addEventListener("beforeunload", unloadCallback);
    } else {
      window.removeEventListener("beforeunload", unloadCallback);
    }
  
    return () => window.removeEventListener("beforeunload", unloadCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewards]);

  useEffect(() => {
    firstLoad(searchParams.get("id"));
    setPlayed(false);
    setRewards(0);
    setFirstPlay(false)
    setVideoLoadStatus('')
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <Layout withBg beforeToExit={beforeToExit}>
      <Grid
        container
        wrap="nowrap"
        gap={6}
        mb={6}
        className={styles.gridContainerVideo}
      >
        <Grid item>
          <div className={styles.containerVideo} style={videoInfo?.imageUrl ? {backgroundImage: `url(${videoInfo?.imageUrl})`} : {}}>
            {loading ? <></> : renderContentVideo()}
          </div>
        </Grid>
        <Grid item>
          <p className={styles.nameVideo}>Reproduce y Gana</p>
          <Grid
            container
            className={styles.containerScrollInfo}
            pt={3}
            wrap='nowrap'
          >
            <Grid item>
              <img
                alt="author"
                src={ownerInfo?.image || Logo}
                className={styles.imageAuthor}
            />
            </Grid>
            <Grid item>
              <p className={styles.nameAuthor}>{videoInfo?.name}</p>
              <p className={styles.description}>{videoInfo?.description}</p>
            </Grid>
          </Grid>

          


          <Grid container justifyContent='space-between' alignItems='center' py={2} className={styles.contLinkArtist} >
            <Grid item>
              <button className={styles.buttonHost} onClick={beforeToExit(() => navigate(`/profile-videos?channel=${videoInfo?.owner}`))} style={{flexDirection: videoInfo?.urlSocial ? 'column' : 'row', gap: videoInfo?.urlSocial ? 0 : 24}}>
                <p className={styles.labelHost}>Hosted by:</p>
                <div className={styles.badgeHost}>
                  <Visibility className="off" />
                  <Star className="on" />
                  <p className={styles.nameHost}>{ownerInfo?.name || 'No Name'}</p>
                </div>
              </button>
            </Grid>
            {videoInfo?.urlSocial ? <Grid item>
              <a className={styles.linkArtist} href={videoInfo?.urlSocial}>{'⭐'} Contactar al artista</a> 
            </Grid> : <></>}
          </Grid>
          

          <p className={styles.nameVideo} style={{cursor: 'pointer'}} onClick={() => setShowTooltip(prev => !prev)}>
            Recompensas{" "}
            <Tooltip title={'Así de fácil ganas'} text={'Mientras vas progresando en el contenido irás generando FOINTS, los cuales funcionan para subir tu posición en el Ranking y alcanzar a obtener tu parte de la bolsa de premios. Y recuerda que cada contenido tiene un límite máximo para generar FOINTS.'} open={showTooltip} isIcon>
              <InfoOutlined />
            </Tooltip>
          </p>
          <Grid
            container
            wrap="wrap"
            justifyContent="center"
            alignItems="center"
            position='relative'
          >
            <Grid item>
              <img
                alt="FROIN"
                src={Logo}
                className={[
                  styles.iconTotalRewards,
                  played ? styles.loadPoint : "",
                ].join(" ")}
              />
            </Grid>
            <Grid item>
              <p className={styles.totalRewards}>{returnNumFormated(rewards)}</p>
            </Grid>
            <Grid item>
              <span className={styles.labelTotalRewards}>FOINTS</span>
            </Grid>
            <Grid item>
              <button className={[styles.requestRewards, rewards > 0 ? styles.availableReward : ''].join(' ')} onClick={onRequestRewards}>
                <EmojiEvents /> <span>Reclamar</span>
              </button>
            </Grid>
            {videoInfo?.limit ? <div className={styles.labelLimit}>Puedes generar hasta {returnNumFormated(videoInfo?.limit)} FOINTS</div> : <></>}
          </Grid>
        </Grid>
      </Grid>

      {videoInfo.provider === 'twitch' ? <iframe
        src={`https://www.twitch.tv/embed/${videoInfo?.url}/chat?parent=localhost&parent=froin.club&parent=www.foin.club&parent=test.froin.club`}
        title="chat"
        className={styles.containerChat}
      /> : <></>}

      <SuggestedVideos
        title={"Puedes continuar viendo"}
        data={recommendedVideos}
        beforeToExit={beforeToExit}
      />
    </Layout>
  );
}

export default DetailVideoScreen;
