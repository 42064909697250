import { makeStyles } from "@mui/styles";
import { colors, Commons } from "../../styleguide";

const styles = makeStyles(() => ({
  nameVideo: {
    color: colors.white,
    fontSize: 36,
    fontWeight: 600,
    margin: "0 0 18px 0",
    [Commons.smallQuery]: {
      fontSize: 30,
      textAlign: 'center'
    },
  },
  rowRecommended: {
    ...Commons.flexRow,
    flexWrap: "nowrap",
    overflowY: "auto",
    marginBottom: 60,
    transition: 'all 0.3s',
    [Commons.smallQuery]: {
      marginBottom: 30,
    },
  },
  autoScroll: {
    animation: `$autoScroll 3s ease infinite`,
  },
  noAutoScroll: {
    paddingLeft: '0px !important'
  },
  containerRecommenedVideo: {
    position: 'relative',
    margin: "30px 30px 0 30px",
    [Commons.smallQuery]: {
      marginRight: 18,
    },
  },
  playerRecommenedVideo: {
    height: 300,
    width: 420,
    backgroundColor: colors.gray50,
    borderRadius: 24,
    overflow: "hidden",
    position: "relative",
    transition: "0.2s all",
    boxShadow: `0 0 18px 6px ${colors.white}25`,
    [Commons.smallQuery]: {
      height: "auto",
      width: 280,
      aspectRatio: "3 / 2",
    },
    "&:hover": {
      boxShadow: `0 0 25px 17px ${colors.secondary}50`,
    },
  },
  iconPlayer: {
    position: "absolute",
    width: 102,
    height: 102,
    [Commons.smallQuery]: {
      width: 90,
      height: 90,
    },
  },
  overlayPlayer: {
    ...Commons.flexCenter,
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.75)",
    left: 0,
    top: 0,
    transition: "all 0.3s",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.25)",
    },
  },
  nameAuthorRecommended: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 600,
    margin: "18px 0 6px 0",
    ...Commons.ellipsis,
    maxWidth: 420,
    [Commons.smallQuery]: {
      fontSize: 18,
      maxWidth: 280,
    },
  },
  descriptionRecommended: {
    color: colors.white,
    fontSize: 18,
    margin: 0,
    height: 66,
    overflow: "hidden",
    maxWidth: 420,
    [Commons.smallQuery]: {
      fontSize: 16,
      maxWidth: 280,
    },
  },
  imageVideo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  newBadge: {
    position: 'absolute',
    top: 12,
    left: 12,
    backgroundColor: colors.primary,
    color: colors.white,
    borderRadius: 12,
    padding: '6px 12px',
    fontSize: 18,
    boxShadow: colors.shadowWhite,
    fontWeight: 600,
    [Commons.smallQuery]: {
      fontSize: 12
    },
  },
  "@keyframes autoScroll": {
    "0%": {
      paddingLeft: 0,
    },
    "25%": {
      paddingLeft: 60,
    },
    "50%": {
      paddingLeft: 0,
    },
    "100%": {
      paddingLeft: 0,
    },
  },
}));

export default styles;
