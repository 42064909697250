import * as React from "react";
import { Tooltip } from "@mui/material";
import pageStyles from "./styles";
import { Cancel } from "@mui/icons-material";

interface TooltipComponentProps {
  children: any, 
  title: string; 
  text: string;
  open?: boolean;
  isIcon?: boolean;
}

const TooltipComponent = ({ children, title, text, open, isIcon }: TooltipComponentProps) => {
  const styles = pageStyles();

  if (typeof open === 'boolean') {
    return (
      <Tooltip 
        open={open}
        title={<>
          <div className={styles.infoTooltip}>
            <h5>{title}</h5>
            <p>{text}</p>
          </div>
          {isIcon ? <Cancel className={styles.iconClose} /> : <></>}
        </>
        }
        placement='bottom-end'
        PopperProps={{className: styles.contInfoTooltip}}
        slotProps={{
          popper: {
            modifiers: [{
              name: 'offset',
              options: {
                offset: [6, -36]
              }
            }]
          }
        }}
      >
        {children}
      </Tooltip>
    );
  }

  return (
    <Tooltip title={<div className={styles.infoTooltip}>
        <h5>{title}</h5>
        <p>{text}</p>
      </div>
    }>
      {children}
    </Tooltip>
  );
}

export default TooltipComponent;
