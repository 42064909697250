import * as React from "react";
import { Layout } from "components";
import ReceiveView from "./ReceiveView";

function ReceiveScreen() {
  return (
    <Layout withBg>
      <ReceiveView />
    </Layout>
  );
}

export default ReceiveScreen;
