import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  contInfoTooltip: {
    backgroundImage: `linear-gradient(35deg, black 75%, ${colors.themeColor})`,
    padding: 12,
    borderRadius: 12,
    boxShadow: colors.shadowWhite,
    '& .MuiTooltip-tooltipPlacementBottom': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
      margin: '0 !important',
      borderRadius: 12,
    }
  },
  infoTooltip: {
    '& h5': {
      color: colors.orange,
      fontSize: 24,
      fontWeight: '600',
      margin: '12px 0 6px 0',
    },
    '& p': {
      color: colors.white,
      fontSize: 18,
      fontWeight: '400',
      margin: 0
    },
  },
  iconClose: {
    color: colors.white,
    top: 6,
    right: 6,
    position: 'absolute',
    fontSize: '30px !important',
    animation: "$delayDisplay .75s linear",
    opacity: 1,
    [Commons.smallQuery]: {
      fontSize: '24px !important',
    },
  },
  "@keyframes delayDisplay": {
    "0%": {
      opacity: 0
    },
    "50%": {
      opacity: 0
    },
    "100%": {
      opacity: 1
    },
  },
});

export default styles;
