const returnThemeColor = () => {
  const randColor = () => {
    const colorsRand = ["#61D095", "#FF7F11", "#40CFFF", "#6758F3", "#FC1978"];
    const indexRand = Math.floor(Math.random() * colorsRand.length);
    return colorsRand[indexRand];
  };

  try {
    const localData = localStorage.getItem("themeColor");
    return localData || randColor();
  } catch (e) {
    return randColor();
  }
};

const colors = {
  returnThemeColor,
  themeColor: returnThemeColor(),
  black: "#000000",
  blackLight: "#181818",
  white: "#FFFFFF",
  primary: "#61D095",
  orange: "#FF7F11",
  blue: "#40CFFF",
  secondary: "#6758F3",
  red: "#FF6663",
  yellow: "#F9B72D",
  mainxText: "#27313F",
  secondaryText: "#4F6178",
  icon: "#A9B4C2",
  gray50: "#8F959C",
  grayBlack: "#EBEBEC",
  grayLight: "#F6F6F6",
  pink: "#FC1978",
  darkPurple: "#16123C",
  shadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.3)",
  shadow2: "6px 6px 12px 6px rgba(0, 0, 0, 0.1)",
  shadowWhite: "0px 0px 12px 6px rgba(255, 255, 255, 0.3)",
  shadowWhiteInset: "inset 0px 0px 24px 18px rgba(255, 255, 255, 0.3)",
  shadowPurlpeInset: "inset 0px 0px 50px 3px #6758F3",
  textShadow: "0px 0px 6px #000000",
};

export default colors;
