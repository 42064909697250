import * as React from "react";
import ReactPlayer from "react-player/youtube";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import PlayCircleImage from "assets/icons/play_circle.svg";
import pageStyles from "./styles";
import * as moment from "moment";

// interface SuggestedItemVideo {
//   name: string;
//   description: string;
//   url: string;
// }

interface SuggestedVideosProps {
  title: string;
  data: any[];
  beforeToExit?: (callback: () => void) => () => void;
}

const SuggestedVideos = ({ title, data, beforeToExit }: SuggestedVideosProps) => {
  const styles = pageStyles();
  const navigate = useNavigate();

  const [scrolled, setScrolled] = useState(false)

  const handleRedirect = (callback: () => void) => () => {
    if (beforeToExit) {
      beforeToExit(callback)()
    } else {
      callback()
    }
  }

  const renderVideos = () =>
    data.map(({ name, description, url, id, imageUrl, created }, index) => (
      <div className={styles.containerRecommenedVideo} key={`${name}-${index}`}>
        <div
          className={styles.playerRecommenedVideo}
          onClick={handleRedirect(() => navigate(`/detail-video?id=${id}`))}
        >
          {imageUrl ? <img src={imageUrl} className={styles.imageVideo} alt="Video" /> : <ReactPlayer
            url={url}
            light
            width="100%"
            height="100%"
            playing={false}
            playIcon={<></>}
          />}
          <div className={styles.overlayPlayer}>
            <img
              alt="Play"
              className={styles.iconPlayer}
              src={PlayCircleImage}
            />
          </div>
        </div>
        <p className={styles.nameAuthorRecommended}>{name}</p>
        <p className={styles.descriptionRecommended}>{description}</p>
        {moment().diff(moment(created, 'x'), 'd') < 7 ? <div className={styles.newBadge}>Recien agregado</div> : <></>}
      </div>
    ));

  const handleScroll = () => {
    setScrolled(true)
  }

  useEffect(() => {
    const callback = setTimeout(handleScroll, 9000);

    return () => clearTimeout(callback)
  }, []);

  if (data?.length > 0) {
    return (
      <>
        <p className={styles.nameVideo}>{title}</p>
        <div className={[styles.rowRecommended, scrolled ? styles.noAutoScroll : styles.autoScroll].join(' ')} onScroll={handleScroll}>{renderVideos()}</div>
      </>
    );
  }

  return <></>;
};

export default SuggestedVideos;
