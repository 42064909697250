import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  title: {
    color: colors.orange,
    fontSize: 48,
    margin: 0,
    fontWeight: 600,
  },
  description: {
    color: colors.white,
    fontSize: 24,
    margin: "18px 0 60px 0",
    fontWeight: 400,
    maxWidth: 780,
  },
  button: {
    ...Commons.defaultButton,
    backgroundColor: colors.secondary,
    color: colors.white,
    padding: "12px 30px",
    borderRadius: 30,
    fontSize: 18,
    border: `3px solid black`,
    "&:hover": {
      borderColor: colors.white,
    },
  },
});

export default styles;
