import { useEffect } from "react";

const useIsMobile = (callback) => {
  function handleWindowSizeChange() {
    const deviceIsMobile = window.innerWidth <= 780;
    callback?.(deviceIsMobile);
  }

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
    //eslint-disable-next-line
  }, []);
};

export default useIsMobile;
