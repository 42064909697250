import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  backdrop: {
    zIndex: 100,
  },
  container: {
    ...Commons.flexRow,
    ...Commons.centerHorizontal,
    position: "fixed",
    bottom: -2000,
    zIndex: 100,
    backgroundColor: colors.black,
    padding: "18px 30px",
    border: `6px solid ${colors.orange}`,
    borderBottom: "none",
    borderRadius: "30px 30px 0 0",
    width: "100%",
    boxSizing: "border-box",
    overflow: "auto",
    maxHeight: "90vh",
    transition: "all 0.2s",
    maxWidth: 960,
    [Commons.smallQuery]: {
      flexDirection: "column",
    },
  },
  showContainer: {
    bottom: 0,
  },
  title: {
    color: colors.orange,
    fontSize: 30,
    margin: 0,
    fontWeight: 600,
    marginBottom: 12,
  },
  text: {
    color: colors.white,
    fontSize: 18,
    margin: "0 0 12px 0",
    [Commons.smallQuery]: {
      fontSize: 16,
    },
  },
  colText: {
    flex: 1,
    marginRight: 90,
    [Commons.smallQuery]: {
      marginRight: 0,
      flex: "unset",
    },
  },
  colButtons: {
    [Commons.smallQuery]: {
      width: "100%",
    },
  },
  downLoadBbutton: {
    ...Commons.defaultButton,
    backgroundColor: colors.orange,
    borderRadius: 30,
    color: colors.white,
    width: "100%",
    padding: "12px 6px",
    margin: "12px 0",
    fontSize: 18,
    fontWeight: 600,
  },
  dismissButton: {
    ...Commons.defaultButton,
    backgroundColor: colors.white,
    borderRadius: 30,
    color: colors.black,
    width: "100%",
    padding: "12px 6px",
    marginBottom: 6,
    fontSize: 18,
    fontWeight: 600,
  },
  hideButton: {
    ...Commons.defaultButton,
    backgroundColor: "transparent",
    borderRadius: 30,
    color: colors.white,
    width: "100%",
    margin: "12px 0",
    fontSize: 18,
    fontWeight: 600,
  },
});

export default styles;
