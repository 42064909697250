import * as React from "react";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { useNavigate } from "react-router-dom";

import LogoFroin from "assets/logos/LogoFroinBlanco.png";
import { ElementsParallax, Layout } from "components";
import pageStyles from "./styles/welcomeStyles";
import Model from "hooks/Model";

import BannerHome from "../home/BannerHome";
import RolesInfo from "../home/RolesInfo";
import FeaturesHome from "../home/FeaturesHome";
import detectIncognito from "detectincognitojs";
import LocalStorage from "utils/localStorage";
import { ChevronLeft } from "@mui/icons-material";

const Welcome = () => {
  const navigate = useNavigate();
  
  const styles = pageStyles();
  const [currentView, setCurrentView] = React.useState('intro');
  const [animationOpacity, setAnimationOpacity] = React.useState('on');

  const saveCompleteWelcome = async (callback: () => void) => {
    try {
      const result = await detectIncognito();

      LocalStorage(result.isPrivate).setItem("completedWelcome", "true");
      Model.setData("completedWelcome", true);
      callback()
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    }
  }

  const changeView = (view: string) => {
    setAnimationOpacity('off')
    
    setTimeout(() => {
      setCurrentView(view)
      setAnimationOpacity('on')
      window.scrollTo(0, 0);
    }, 1000);
  }

  const renderView = () => {
    switch (currentView) {
      case 'intro':
        return <div className={animationOpacity === 'on' ? styles.animatedOnDiv : styles.animatedOffDiv}>
          <BannerHome showButtons onPressButton={changeView} />
        </div>
      case 'roles':
        return <div className={animationOpacity === 'on' ? styles.animatedOnDiv : styles.animatedOffDiv}>
          <RolesInfo isIntro callback={saveCompleteWelcome} />
          <button className={styles.backButton} onClick={() => changeView('intro')}>
            <ChevronLeft fontSize='large' />
            <span>Volver</span>
          </button>
        </div>
      case 'benefits':
        return <div className={animationOpacity === 'on' ? styles.animatedOnDiv : styles.animatedOffDiv}>
          <FeaturesHome />
          <button className={styles.backButton} onClick={() => changeView('intro')}>
            <ChevronLeft fontSize='large' />
            <span>Volver</span>
          </button>
        </div>
      default:
        return <></>
    }
  }

  React.useEffect(() => {
    if (currentView === 'recommended') {
      saveCompleteWelcome(() => navigate('/profile-videos'))
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView]);

  React.useEffect(() => {
    Model.setData("loading", false);
    Model.setData("opacity", 0.7);

  }, []);

  return (
    <Layout withBg noheader noFooter>
      <ElementsParallax variant="leftBg" />
      <ElementsParallax variant="rightBg" />
      <img alt="FROIN" src={LogoFroin} className={styles.logo} />
      {renderView()}
    </Layout>
  );
};

export default Welcome;
