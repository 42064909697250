class model_class {
  dispatch = null;
  get_dispatch = () => (this.dispatch == null ? false : this.dispatch);
  set_dispatch = (n_dispatch) => {
    this.dispatch = n_dispatch;
  };

  setData = (label, payload) => {
    this.dispatch({ type: "SET_STORE", label, payload });
  };

  updateInfoUser = (payload) => {
    this.dispatch({ type: "UPDATE_INFO_USER", payload });
  };

  resetData = () => {
    this.dispatch({ type: "RESET_DATA" });
  };

  updateAlerts = (payload) => {
    this.dispatch({ type: "UPDATE_ALERT", payload });
  };
}

const Model = new model_class();

export default Model;
