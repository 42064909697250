import { makeStyles } from "@mui/styles";
import { colors } from "styleguide";

const styles = makeStyles({
  container: {
    border: `6px solid ${colors.orange}`,
    borderRadius: "18px !important",
  },
  button: {
    color: `${colors.secondary} !important`,
  },
});

export default styles;
