import { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@mui/styles";

import { colors } from "styleguide";

interface BaseIconProps {
  icon: any;
  withoutAnimation?: boolean;
}

const BaseIcon = ({ icon, withoutAnimation }: BaseIconProps) => {
  const [color, setColor] = useState(colors.returnThemeColor());
  const styles = useStyles();

  useEffect(() => {
    let callback = null

    if (!withoutAnimation) {
      callback = setTimeout(() => {
        setColor(colors.returnThemeColor());
      }, 2000);
    }

    return () => {
      clearTimeout(callback);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  return useCallback(
    () => icon(color, styles),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [color]
  )();
};

const useStyles = makeStyles({
  container: {
    "& *": {
      transition: "all 1s",
    },
  },
});

export default BaseIcon;
