import * as React from "react";
import { Grid } from "@mui/material";
import * as moment from "moment";
import { InfoRounded } from "@mui/icons-material";

import { Layout, ElementsParallax, Tooltip } from "components";
import { returnNumFormated } from "utils/validations";
import Logocrom from "assets/video/Logocrom.gif";
import pageStyles from "./RankingStyles";
import { useRanking } from "services";

const initTooltips = {
  one: false,
  two: false,
  three: false,
  four: false,
}

function RankingScreen() {
  const styles = pageStyles();
  const [openTooltips, setOpenTooltips] = React.useState(initTooltips)

  const { currentScore, settings, currentPosition, listRanking } = useRanking({});

  const renderFinalDate = () => {
    if (settings?.finalDate) {
      const finalDate = moment(settings?.finalDate, "x");
      const currentDate = moment();
      const restDays = finalDate.diff(currentDate, "days")
  
      return restDays > -1 ? restDays : 0;
    }

    return 0
  };

  const returnAproxReward = () => {
    if (settings?.accumulated && settings?.prize && currentScore) {
      return ((currentScore / settings.accumulated) * settings?.prize).toFixed(2);
    }

    return 0;
  };

  const renderUsers = () => {
    return listRanking.map((user, index) => (
      <div className={styles.itemUser} key={index}>
        <div>{index + 1}</div> <p>{user}</p>
      </div>
    ));
  };

  const changeTooltip = (label) => () => {
    setOpenTooltips(prev => ({
      ...initTooltips,
      [label]: !prev[label]
    }))
  }

  return (
    <Layout withBg>
      <ElementsParallax variant="leftBg" />
      <div className={styles.containerBanner}>
        <div className={styles.colInfo}>
          <div className={styles.infoContainer}>
            <h2>Ranking general</h2>
            <p>
              A continuación puedes encontrar la información sobre tu progreso
              en el ranking, comparar con el puntaje de otros usuarios, ver los
              premios y los dias restantes.
            </p>
          </div>
          {settings?.infoBanner?.show ?<div className={styles.infoContainerNews}>
            <h2>{settings.infoBanner.title}</h2>
            <p>
            {settings.infoBanner.description}
            </p>
          </div> : <></>}
        </div>
        <div className={styles.imageRanking}>
          <div className={styles.containerImageLogo}>
            <img src={Logocrom} alt="FROIN" className={styles.imageLogo} />
          </div>
          <div className={styles.overlayVideo} />
        </div>
      </div>
      <Grid container className={styles.gridContainer}>
        
          <Grid item className={[styles.itemSquare, styles.itemSquareMidleMobile].join(' ')} onClick={changeTooltip('one')}>
            <div className={styles.divisionSquare}>
              <p className={styles.labelItemSquare}>Posición actual:</p>
              <p className={styles.valueItemSquare}>{currentPosition < 1 ? '+50' : currentPosition}</p>
            </div>
            <Tooltip open={openTooltips.one} title="Gana cualquier posición" text="Nuestro objetivo es que las ganancias sean equitativas para todos nuestros participantes, así que no te preocupes si apareces después de la posición 50, tu recompensa llegará" isIcon>
              <InfoRounded className={styles.iconInfo}/>
            </Tooltip>
          </Grid>
          <Grid item className={[styles.itemSquare, styles.itemSquareMidleMobile].join(' ')} onClick={changeTooltip('two')}>
            <div className={styles.divisionSquare}>
              <p className={styles.labelItemSquare}>Días restantes:</p>
              <p className={styles.valueItemSquare}>{returnNumFormated(renderFinalDate())}</p>
            </div>
            <Tooltip open={openTooltips.two} title="El tiempo del Ranking" text="El tiempo que damos para que cada participante pueda acumular puntaje (FOINTS) es de una semana, por lo tanto estos son los días que quedan antes de que se cierre y ya no se puedan acumular más FOINTS" isIcon>
              <InfoRounded className={styles.iconInfo}/>
            </Tooltip>
          </Grid>
          <Grid item className={styles.itemSquare} onClick={changeTooltip('three')}>
            <div className={styles.divisionSquare}>
              <p className={styles.labelItemSquare}>FOINTS acumulados:</p>
              <p className={styles.valueItemSquare}>{returnNumFormated(currentScore || 0)}</p>
            </div>
            <div className={styles.divisionSquare}>
              <p className={styles.labelItemSquare}>Total FOINTS comunidad:</p>
              <p className={styles.valueItemSquare}>{returnNumFormated(settings?.accumulated || 0)}</p>
            </div>
            <Tooltip open={openTooltips.three} title="Es FOINTS no POINTS" text="El premio que te correspondrá al finalizar el Ranking será proporcional a los FOINTS que generaste frente a los FOINTS de la comunidad. Ejemplo: Si tus FOINTS corresponden al 10% de todos los FOINTS generados por la comunidad, entonces te correspondrá el 10% del premio acumulado." isIcon>
              <InfoRounded className={styles.iconInfo}/>
            </Tooltip>
          </Grid>
          <Grid item className={styles.itemSquare} onClick={changeTooltip('four')}>
            <div className={styles.divisionSquare}>
              <p className={styles.labelItemSquare}>
                Premio acumulado en $FROIN:
              </p>
              <p className={styles.valueItemSquare}>{settings?.prize}</p>
            </div>
            <div className={styles.divisionSquare}>
              <p className={styles.labelItemSquare}>Tu premio actual es de:</p>
              <p className={styles.valueItemSquare}>{returnNumFormated(parseFloat(String(returnAproxReward())))} </p>
            </div>
            <Tooltip open={openTooltips.four} title="Aquí todos ganan" text="Te mostramos el premio que te corresponde según tu participación en la comunidad, aprovecha cada día para acumular más FOINTS y subir en el Ranking" isIcon>
              <InfoRounded className={styles.iconInfo}/>
             </Tooltip>
          </Grid>
        <Grid item className={styles.itemSquare}>
          <p className={styles.labelItemSquare}>Primeros 50 usuarios</p>
          <div className={styles.usersList}>{renderUsers()}</div>
        </Grid>
        <Grid item className={styles.itemSquare}>
          <p className={styles.labelItemSquare}>Términos y condiciones</p>
          <div className={styles.scrollTerms}>
            <p className={styles.textTerms}>
              {settings?.terms?.replace(/\\n/g, '\n')}
            </p>
          </div>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default RankingScreen;
