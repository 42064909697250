import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  containerTwitchPlayer: {
    width: '100%',
    height: '100%',
  },
});

export default useStyles;
