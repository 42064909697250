import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Store } from "hooks/main_store";
import Model from "hooks/Model";
import pageStyles from "./styles";

const DialogComponent = () => {
  const { state } = React.useContext(Store);
  const styles = pageStyles();
  const handleClose = state?.dialog?.onClose || (() => {});

  const renderLink = () => {
    const subtext = state?.dialog?.text || "";
    const linktext = state?.dialog?.link || "";
    const startIndex = subtext.indexOf("[[");
    const endIndex = subtext.indexOf("]]");
    const linkText = subtext.slice(startIndex + 2, endIndex);
    const leftText = subtext.slice(0, startIndex);
    const rightText = subtext.slice(endIndex + 2);

    if (startIndex > -1 && linktext) {
      return (
        <span>
          {leftText}
          <a href={linktext} target="blank">
            {linkText}
          </a>
          {rightText}
        </span>
      );
    }

    return subtext;
  };

  return (
    <Dialog
      open={state?.dialog?.open || false}
      onClose={() => {
        Model.setData("dialog", { open: false });
        handleClose();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: styles.container }}
    >
      <DialogTitle id="alert-dialog-title">
        {state?.dialog?.title || ""}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {renderLink()}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {state?.dialog?.txtAux ? (
          <Button
            onClick={() => {
              const fnAux = state?.dialog?.fnAux || (() => {});
              fnAux();
            }}
            color="primary"
            className={styles.button}
          >
            {state?.dialog?.txtAux || ""}
          </Button>
        ) : null}
        {state?.dialog?.txtLeft ? (
          <Button
            onClick={() => {
              const fnLeft = state?.dialog?.fnLeft || (() => {});
              fnLeft();
            }}
            color="primary"
            className={styles.button}
          >
            {state?.dialog?.txtLeft || ""}
          </Button>
        ) : null}
        <Button
          onClick={() => {
            const fnRight = state?.dialog?.fnRight || (() => {});
            fnRight();
          }}
          color="primary"
          className={styles.button}
        >
          {state?.dialog?.txtRight || ""}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogComponent;
