import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  leftBg: {
    position: "fixed",
    zIndex: 0,
    left: 0,
  },
  rightBg: {
    position: "fixed",
    zIndex: 0,
    right: 0,
  },
});

export default useStyles;
