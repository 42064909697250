import * as React from "react";
import { useContext, useEffect } from "react";
import QRCode from "qrcode.react";
import { useTranslation } from "react-i18next";

import { returnCurrentLink } from "utils/validations";
import pageStyles from "./styles/ReceiveViewStyles";
import { Store } from "hooks/main_store";
import Model from "hooks/Model";

function SendForm() {
  const { t } = useTranslation();
  const styles = pageStyles();
  const {
    state: { infoUser },
  } = useContext(Store);

  useEffect(() => {
    Model.setData("loading", false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h2 className={styles.title}>
        {t("pages.receive.receiveView.receive")} $FROIN
      </h2>
      <p className={styles.description}>
        {t("pages.receive.receiveView.receiveTokens")}{" "}
        <b>({infoUser?.email})</b> {t("pages.receive.receiveView.orYouCan")}{" "}
        <a href="mailto:help@froin.co">
          {t("pages.receive.receiveView.support")}
        </a>
        .
      </p>

      <p className={styles.labelInput}>
        {t("pages.receive.receiveView.qrCode")}
      </p>
      <div className={styles.contQr}>
        <QRCode
          level="H"
          value={`${returnCurrentLink("/send")}?receiver=${infoUser?.email}`}
          size={210}
        />
      </div>
    </>
  );
}

export default SendForm;
