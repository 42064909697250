import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const useStyles = makeStyles({
  contLoader: {
    ...Commons.flexCenter,
    flexDirection: "column",
    backgroundColor: "rgba(0, 0, 0, 1)",
    position: "fixed",
    height: "100%",
    width: "100vw",
    zIndex: 100,
    left: "0px",
    top: "0px",
    "& svg": {
      color: colors.orange,
    },
  },
  imageLogo: {
    width: 240,
    height: "auto",
    aspectRatio: "1 / 1",
  },
  circularProgress: {
    color: colors.orange
  },
  labelCircularProgress: {
    color: colors.orange,
    fontSize: 12,
    textAlign: 'center',
    position: 'absolute',
  },
});

export default useStyles;
