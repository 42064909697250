import * as React from "react";
import { Component } from "react";
import { ParallaxProvider } from "react-scroll-parallax";

import { Store, StoreProvider } from "hooks/main_store";
import { Alert, Loading, Dialog, InstallPWA, AuthModal, IncognitoAlert, NewVersionModal } from "components";
import Model from "hooks/Model";
import Routes from "./routes";
import { usePreLoadImages } from "services";

const InitHooksClasses = () => {
  const { dispatch } = React.useContext(Store);

  React.useEffect(() => {
    Model.set_dispatch(dispatch);
    return () => {};
  }, [dispatch]);

  return <React.Fragment />;
};

const Content = () => {
  const { loading } = usePreLoadImages()

  return loading ? <></> : <Routes />
}

class Container extends Component {
  render() {
    return (
      <>
        <StoreProvider>
          <InitHooksClasses />
          <ParallaxProvider>
            <Content />
          </ParallaxProvider>
          <InstallPWA />
          <AuthModal />
          <Loading />
          <Dialog />
          <Alert />
          <NewVersionModal />
          <IncognitoAlert />
        </StoreProvider>
      </>
    );
  }
}

export default Container;
