import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  title: {
    margin: 0,
    color: colors.orange,
    fontSize: 42,
    fontWeight: 600,
    [Commons.smallQuery]: {
      fontSize: 30,
    },
  },
  description: {
    color: colors.white,
    fontSize: 18,
    textAlign: "left",
    margin: "12px 0",
    whiteSpace: "break-spaces",
    "& a": {
      color: colors.orange,
      textDecoration: "none",
    },
  },
  input: {
    maxWidth: 540,
  },
  labelInput: {
    color: colors.orange,
    fontSize: 18,
    fontWeight: 400,
    margin: "60px 0 0 0",
    [Commons.smallQuery]: {
      margin: "30px 0 0 0",
    },
  },
  button: {
    ...Commons.defaultButton,
    backgroundColor: colors.secondary,
    color: colors.white,
    fontWeight: 600,
    fontSize: 24,
    width: "100%",
    margin: "120px 0 12px 0",
    borderRadius: 30,
    padding: "18px 0",
    transition: "all 0.3s",
    maxWidth: 540,
    display: "block",
    "&.disabled": {
      backgroundColor: colors.gray50,
      cursor: "no-drop",
    },
    [Commons.smallQuery]: {
      margin: "60px 0 12px 0",
    },
  },
});

export default styles;
