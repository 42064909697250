import {useEffect, useState} from "react";
import * as React from "react";
import useStyles from "./styles";
import * as Twitch from "utils/twitch_v1";

interface TwitchPlayerProps {
  url: string;
  width?: string;
  height?: string;
  progressInterval?: number;
  onProgress?: () => void;
  onPause?: () => void;
  onEnded?: () => void;
  onError?: () => void;
  onPlay?: () => void;
}

const TwitchPlayer = ({ url, width, height, progressInterval, onPlay, onPause, onEnded, onError, onProgress }: TwitchPlayerProps) => {
  const styles = useStyles();
  const [playing, setPlaying] = useState(false)
  const [callback, setCallback] = useState<any>()

  useEffect(() => {
    clearTimeout(callback)

    if (playing) {
      setCallback(setTimeout(() => {
        onProgress()
        setPlaying(false)
        setTimeout(() => {
          setPlaying(true)
        }, 250);
      }, progressInterval));
    }

    return () => clearTimeout(callback)
    // eslint-disable-next-line
  }, [playing])

  const ttt = React.useCallback(() => {
    setPlaying(true)
    onPlay()
    // eslint-disable-next-line
  }, [playing])

  useEffect(() => {
    const options = {
      width,
      height,
      channel: url,
      parent: ["froin.club"],
      autoplay: false,
      layout: 'video'
    };

    document.getElementById('TwitchPlayer').innerHTML = ''

    const player = new Twitch.Player("TwitchPlayer", options);

    player.addEventListener(Twitch.Player.PLAY, ttt)
    player.addEventListener(Twitch.Player.ENDED, () => {
      setPlaying(false)
      onEnded()
    })
    player.addEventListener(Twitch.Player.PAUSE, () => {
      setPlaying(false)
      onPause()
    })
    player.addEventListener(Twitch.Player.OFFLINE, () => {
      setPlaying(false)
      onError()
    })

    return () => {
      player.removeEventListener(Twitch.Player.PLAY)
      player.removeEventListener(Twitch.Player.ENDED)
      player.removeEventListener(Twitch.Player.PAUSE)
      player.removeEventListener(Twitch.Player.OFFLINE)
    }
    // eslint-disable-next-line
  }, [])

  return <div id="TwitchPlayer" className={styles.containerTwitchPlayer}></div>
}

export default TwitchPlayer;
