import { makeStyles } from "@mui/styles";
import { Commons, colors } from "styleguide";

const styles = makeStyles({
  container: {
    ...Commons.flexCenter,
    width: "100%",
    backgroundColor: colors.orange,
    color: colors.white,
    textAlign: "center",
    padding: "18px 0",
    fontWeight: 500,
    fontSize: 18,
  },
});

export default styles;
