import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  container: {
    ...Commons.flexCenter,
    flexDirection: "column",
    position: "relative",
    background: colors.black,
    width: "100%",
  },
  img: {
    width: 180,
    height: "auto",
  },
  text: {
    textAlign: "center",
    fontSize: 30,
    maxWidth: 480,
    margin: 30,
    color: colors.white,
  },
  button: {
    backgroundColor: colors.orange,
    padding: "12px 24px",
    borderRadius: 30,
    color: `${colors.white} !important`,
    textAlign: "center",
    textDecoration: "none !important",
    fontSize: "24px !important",
  },
});

export default styles;
