import * as React from "react";
import { CircularProgress } from "@mui/material";

import Logocrom from "assets/video/LogocromFast.gif";
import { Store } from "hooks/main_store";
import useStyles from "./styles";

const LoadingComponent = () => {
  const { state } = React.useContext(Store);
  const styles = useStyles();

  if (state.loading) {
    return (
      <div
        className={styles.contLoader}
        style={{
          backgroundColor: `rgba(0, 0, 0, ${state.opacity})`,
        }}
      >
        {state.showLogoLoader ? <img src={Logocrom} alt="FROIN" className={styles.imageLogo} /> : <>
          <CircularProgress className={styles.circularProgress} size={60} />
          <span className={styles.labelCircularProgress}>{state.initialLoadProgress} %</span>
        </>}
      </div>
    );
  } else if (state.errorLoad) {
    return <div>Lo sentimos, hay un problema al cargar esta página.</div>;
  } else {
    return null;
  }
};

export default LoadingComponent;
