import * as React from "react";
import { useEffect, useState } from "react";
import { Update } from "@mui/icons-material";

import pageStyles from "./styles";
import { databaseService } from "utils/firebase";
import { Store } from "hooks/main_store";

const IncognitoAlert = () => {
  const styles = pageStyles();

  const { state } = React.useContext(Store);

  const [show, setShow] = useState(false)

  const onReload = () => {
    window.location.reload()
  }

  const firstLoad = async () => {
    try {
      const versionResponse = (
        await databaseService.getDatabase("settings/version")
      ).val();

      if (versionResponse && versionResponse !== state.version) {
        setShow(true)
      }
    } catch (error) {
      console.log("Error get version: ", error);
    }
  };

  useEffect(() => {
    firstLoad()
    // eslint-disable-next-line
  }, [])

  if (show) {
    return (
      <div className={styles.container}>
        <Update className={styles.icon} />
        <p className={styles.message}>
          Disculpa, esta versión ya no está disponible{" "}
          <b>por favor recarga nuevamente la página</b>
        </p>

        <button className={styles.button} onClick={onReload} >Actualizar página</button>

        <img
          alt="FROIN"
          src={require("../../assets/logos/LogoFroinBlanco.png")}
          className={styles.logo}
        />
      </div>
    );
  }

  return <></>
};

export default IncognitoAlert;
