import * as React from "react";
import { detectIncognito } from "detectincognitojs";

import Logocrom from "assets/logos/froin-3d.svg";
import LocalStorage from "utils/localStorage";
import Model from "hooks/Model";
import pageStyles from "./styles";

const OlderVerificationScreen = () => {
  const styles = pageStyles();

  const onDecline = () => {
    window.location.href = 'https://google.com'
  }

  const onAccept = async () => {
    try {
      const result = await detectIncognito();

      LocalStorage(result.isPrivate).setItem("isOlder", "true");
      Model.setData("loading", true);
      Model.setData("isOlder", true);
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      Model.setData("loading", false);
    }, 1000);
    //eslint-disable-next-line
  }, []);

  return (
    <div className={styles.container}>
      <img alt="FROIN" className={styles.img} src={Logocrom} />
      <h1 className={styles.title}>Bienvenide a la revolución</h1>
      <p className={styles.text}>
        Antes de ingresar al ecosistema FROIN, debes confimar que eres mayor de 18 años.
      </p>
      <div className={styles.buttons}>
        <button className={styles.button} onClick={onDecline}>No, salir ahora</button>
        <button className={styles.button} onClick={onAccept}>Si, soy mayor de edad</button>
      </div>
    </div>
  );
};

export default OlderVerificationScreen;
