import { useContext, useEffect, useState } from "react";

import { databaseService } from "utils/firebase";
import { Store } from "hooks/main_store";
import Model from "hooks/Model";

const WalletService = () => {
  const { state } = useContext(Store);

  const [currentBalance, setCurrentBalance] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const getBalance = async () => {
      if (state.infoUser?.uid) {
        const currentBalance: any = (
          (await databaseService.getDatabase(`users/${state.infoUser?.uid}`)).val()?.balance
        ) as unknown as number;

        setCurrentBalance(currentBalance);
      }
  };

  const firstLoad = async () => {
    try {
      setLoading(true)
      
      await getBalance();
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      setLoading(false)
    }
  };


  useEffect(() => {
    firstLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.infoUser?.uid]);

  return {
    currentBalance,
    loading,
  }
}

export default WalletService;
