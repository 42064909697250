import { makeStyles } from "@mui/styles";
import { Commons, colors } from "styleguide";

const styles = makeStyles({
  container: {
    ...Commons.flexRow,
    justifyContent: "space-between",
    width: "100vw",
    position: "fixed",
    top: 0,
    left: 0,
    padding: "24px 42px",
    boxSizing: "border-box",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: 2,
    [Commons.smallQuery]: {
    padding: "12px 18px",
    }
  },
  logo: {
    height: 60,
    [Commons.smallQuery]: {
      height: 36
    }
  },
  link: {
    textDecoration: "none",
  },
  textLink: {
    color: colors.white,
    fontSize: 24,
    textAlign: "center",
    border: `3px solid ${colors.darkPurple}`,
    borderRadius: 20,
    padding: "15px 30px",
    transition: "all 0.2s",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    "&:hover": {
      backgroundColor: colors.darkPurple,
    },
  },
  loginButton: {
    ...Commons.defaultButton,
    ...Commons.flexRow,
    border: `3px solid ${colors.orange}`,
    borderRadius: 20,
    padding: 15,
    transition: "all 0.2s",
    gap: 12,
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    "&:hover": {
      backgroundColor: colors.orange,
    },
    [Commons.smallQuery]: {
      padding: '6px 12px',
      border: `2px solid ${colors.orange}`,
    }
  },
  loginIcon: {
    color: colors.white,
    fontSize: "30px !important",
    [Commons.smallQuery]: {
    fontSize: "18px !important",
    }
  },
  loginLabel: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 600,
    [Commons.smallQuery]: {
      fontSize: 16,
    }
  },
  rowLogin: {
    ...Commons.flexRow,
    gap: 18
  },
  blankButton: {
    height: 54,
    width: 90
  }
});

export default styles;
