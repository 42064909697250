import { makeStyles } from "@mui/styles";
import { Commons } from "styleguide";
import BgBanner from "assets/images/banner_bg.png";

const styles = makeStyles(() => ({
  children: {
    padding: "150px 6vw 60px 6vw",
    boxSizing: "border-box",
    width: "100%",
    minHeight: "calc(100vh - 58px)",
    maxWidth: 1920,
    margin: '0 auto',
    [Commons.midQuery]: {
      padding: "90px 3vw 60px 3vw",
    },
  },
  withBg: {
    ...Commons.coverBackground,
    backgroundSize: "contain",
    backgroundPosition: "top center",
    backgroundImage: `url(${BgBanner})`,
  },
  withoutHeader: {
    paddingTop: 0,
  },
  withFooter: {
    paddingBottom: 0,
  },
}));

export default styles;
