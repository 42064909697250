import * as React from "react";
import BaseIcon from "./baseIcon";

const ArtistIcon = () => {
  return (
    <BaseIcon
      withoutAnimation
      icon={(color, styles) => {
        return (
          <svg
            width="309"
            height="283"
            viewBox="0 0 309 283"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.container}
          >
            <path
              d="M201.128 36.8466C193.755 33.7619 185.165 33.9487 177.279 35.7146C155.903 40.486 138.228 57.0924 133.568 76.7836C132.073 83.1058 131.875 89.9091 134.693 95.8408L134.749 95.9596C136.849 100.175 137.817 104.789 137.573 109.417C137.329 114.044 135.88 118.552 133.346 122.562C126.974 132.478 120.096 141.975 110.678 149.509C103.021 155.616 93.9359 160.212 86.9154 166.97C79.8949 173.728 75.1734 183.752 79.0112 192.383C83.2321 201.909 95.6972 205.752 106.852 206.867C125.299 208.697 143.951 206.226 161.075 199.685C174.159 194.67 186.481 186.984 193.891 175.913C201.301 164.842 200.269 151.162 195.572 138.908C191.994 129.586 190.128 121.22 189.553 113.692C188.445 98.599 193.668 83.6873 204.156 72.0009L204.23 71.916C207.221 68.5709 210.299 65.0164 212.184 61.1167C212.382 60.7035 212.561 60.2903 212.734 59.8771C213.501 58.0682 213.937 56.1559 214.026 54.2171C214.026 54.0587 214.026 53.9002 214.026 53.7474C214.069 46.5139 208.352 39.8634 201.128 36.8466Z"
              fill={color}
            />
            <path
              opacity="0.8"
              d="M201.128 36.8466C193.755 33.7619 185.165 33.9487 177.279 35.7146C155.903 40.486 138.228 57.0924 133.568 76.7836C132.073 83.1058 131.875 89.9091 134.693 95.8408L134.749 95.9596C136.849 100.175 137.817 104.789 137.573 109.417C137.329 114.044 135.88 118.552 133.346 122.562C126.974 132.478 120.096 141.975 110.678 149.509C103.021 155.616 93.9359 160.212 86.9154 166.97C79.8949 173.728 75.1734 183.752 79.0112 192.383C83.2321 201.909 95.6972 205.752 106.852 206.867C125.299 208.697 143.951 206.226 161.075 199.685C174.159 194.67 186.481 186.984 193.891 175.913C201.301 164.842 200.269 151.162 195.572 138.908C191.994 129.586 190.128 121.22 189.553 113.692C188.445 98.599 193.668 83.6873 204.156 72.0009L204.23 71.916C207.221 68.5709 210.299 65.0164 212.184 61.1167C212.382 60.7035 212.561 60.2903 212.734 59.8771C213.501 58.0682 213.937 56.1559 214.026 54.2171C214.026 54.0587 214.026 53.9002 214.026 53.7474C214.069 46.5139 208.352 39.8634 201.128 36.8466Z"
              fill="white"
            />
            <path
              d="M154.5 236.667C220.677 236.667 274.324 233.799 274.324 230.26C274.324 226.722 220.677 223.853 154.5 223.853C88.3228 223.853 34.6758 226.722 34.6758 230.26C34.6758 233.799 88.3228 236.667 154.5 236.667Z"
              fill="#F5F5F5"
            />
            <path
              d="M211.467 82.07C211.483 81.5649 211.32 81.0686 211.003 80.655C210.477 79.9504 209.688 79.4454 208.785 79.2344C208.271 79.111 207.792 78.888 207.38 78.5804C206.969 78.2728 206.634 77.8878 206.399 77.4515V77.0949C206.398 77.0358 206.371 76.9797 206.325 76.9384C206.278 76.8972 206.217 76.8741 206.152 76.8741C206.087 76.8756 206.025 76.8999 205.979 76.942C205.933 76.9842 205.906 77.0409 205.905 77.1005L206.164 90.7185L205.991 90.6506H205.942L205.713 90.5883C204.551 90.2374 202.191 89.8752 200.67 91.9807C200.229 92.5482 199.93 93.1974 199.793 93.8824C199.784 93.9634 199.784 94.0449 199.793 94.1258C199.758 94.4599 199.827 94.7962 199.991 95.0963C200.155 95.3965 200.407 95.6485 200.72 95.8238C200.72 95.8238 203.612 96.6785 205.373 94.788C205.902 94.2069 206.26 93.5112 206.412 92.7674C206.588 92.4395 206.663 92.0733 206.628 91.709L206.449 82.087C206.979 82.8138 207.781 83.3374 208.704 83.5586C209.251 83.6886 209.757 83.931 210.186 84.2675C210.614 84.6039 210.953 85.0254 211.176 85.5C211.196 85.5256 211.222 85.5467 211.252 85.5614C211.282 85.5762 211.315 85.5842 211.349 85.5849H211.393C211.432 85.5759 211.468 85.5546 211.492 85.5248C211.517 85.4949 211.53 85.4582 211.529 85.4207L211.467 82.07Z"
              fill={color}
            />
            <path
              d="M124.051 117.032L124.119 117.383C124.13 117.441 124.165 117.493 124.217 117.528C124.269 117.563 124.334 117.578 124.397 117.57C124.429 117.565 124.46 117.554 124.488 117.539C124.515 117.523 124.539 117.502 124.558 117.478C124.577 117.454 124.591 117.427 124.598 117.398C124.606 117.369 124.607 117.339 124.601 117.309L122.061 103.889C122.12 103.907 122.18 103.92 122.241 103.929H122.296L122.531 103.963C123.767 104.144 126.14 104.172 127.277 101.874C127.623 101.253 127.811 100.568 127.827 99.8707C127.837 99.7899 127.837 99.7083 127.827 99.6274C127.808 99.2892 127.685 98.9629 127.472 98.6864C127.259 98.4098 126.964 98.1944 126.622 98.0652C126.622 98.0652 123.619 97.6237 122.197 99.7632C121.757 100.402 121.502 101.132 121.456 101.886C121.341 102.237 121.329 102.61 121.419 102.967L123.217 112.442L124.051 117.032Z"
              fill={color}
            />
            <path
              d="M80.0372 105.055C80.0856 104.551 79.9509 104.045 79.654 103.618C79.1703 102.89 78.4151 102.347 77.5281 102.089C77.0207 101.942 76.5539 101.697 76.1593 101.369C75.7648 101.042 75.4517 100.641 75.2415 100.193V99.8367C75.2415 99.7767 75.2155 99.7191 75.1691 99.6767C75.1228 99.6342 75.0599 99.6104 74.9943 99.6104C74.9288 99.6104 74.8659 99.6342 74.8195 99.6767C74.7732 99.7191 74.7471 99.7767 74.7471 99.8367L74.2095 113.421C74.1556 113.393 74.0999 113.368 74.0426 113.347H73.9932C73.9252 113.347 73.851 113.291 73.7707 113.268C72.6274 112.86 70.2852 112.385 68.6536 114.4C68.1789 114.945 67.8413 115.579 67.6648 116.256C67.6449 116.337 67.6304 116.418 67.6216 116.5C67.5653 116.835 67.6131 117.178 67.7595 117.489C67.906 117.8 68.1452 118.067 68.4497 118.26C68.4497 118.26 71.2863 119.256 73.1589 117.451C73.7428 116.911 74.1681 116.245 74.3949 115.515C74.5828 115.193 74.6785 114.833 74.673 114.468L74.8336 110.37C75.3136 110.964 75.9797 111.41 76.7433 111.649C77.2851 111.817 77.7787 112.094 78.1874 112.461C78.5961 112.827 78.9094 113.273 79.104 113.766C79.1215 113.793 79.1458 113.816 79.1748 113.833C79.2038 113.849 79.2367 113.859 79.2709 113.862H79.3141C79.3567 113.857 79.3955 113.837 79.4229 113.806C79.4503 113.776 79.4644 113.737 79.4625 113.698L79.5861 111.479C79.6156 110.974 79.4754 110.472 79.1844 110.042C78.6945 109.295 77.9296 108.733 77.0275 108.457C76.1524 108.188 75.4156 107.634 74.9572 106.9L75.0376 104.852C75.5232 105.603 76.2928 106.164 77.2006 106.431C77.7372 106.588 78.2276 106.855 78.6345 107.212C79.0415 107.569 79.3542 108.007 79.549 108.491C79.5665 108.516 79.5901 108.537 79.618 108.553C79.6458 108.569 79.6771 108.578 79.7097 108.581H79.7529C79.794 108.574 79.8314 108.555 79.8593 108.526C79.8872 108.497 79.9041 108.461 79.9074 108.423L80.0372 105.055Z"
              fill={color}
            />
            <path
              d="M109.509 102.91L108.273 93.7126L107.729 89.6601L107.686 89.462L95.6167 90.8147V91.0411L96.1667 95.088L97.4645 104.868L97.1802 104.812C96.6124 104.698 96.0289 104.664 95.4498 104.71C95.2231 104.733 94.9981 104.769 94.7762 104.818C94.5451 104.875 94.3197 104.951 94.1026 105.044C93.3295 105.396 92.7058 105.974 92.3289 106.685C91.8975 107.358 91.6712 108.124 91.6739 108.904C91.6743 109.243 91.7795 109.575 91.9775 109.862C92.1755 110.148 92.458 110.377 92.7924 110.523C92.7924 110.523 95.765 111.089 97.3038 109.046C97.7067 108.518 97.9805 107.918 98.1063 107.285C98.2322 106.652 98.2072 106.001 98.0331 105.378L96.6488 95.0427L107.736 93.8032L108.904 102.355C107.94 102.123 105.573 101.789 104.12 103.612C104.052 103.696 103.988 103.783 103.929 103.872C103.867 103.969 103.805 104.065 103.75 104.167C103.608 104.416 103.482 104.673 103.373 104.936C102.847 106.25 103.138 107.042 103.515 107.489C103.607 107.596 103.713 107.693 103.83 107.778C103.944 107.87 104.074 107.947 104.213 108.004C104.213 108.004 106.636 108.468 108.242 107.048C108.42 106.888 108.582 106.714 108.724 106.527C109.298 105.771 109.606 104.872 109.608 103.952C109.617 103.602 109.584 103.253 109.509 102.91Z"
              fill={color}
            />
            <path
              d="M236.163 105.842L238.833 98.7331L238.962 98.4048L239.457 97.086L240.909 93.2259L240.965 93.0278L229.464 89.4054L229.377 89.6205L227.937 93.4749L224.433 102.791L224.205 102.627C223.749 102.298 223.24 102.034 222.697 101.846C222.259 101.689 221.794 101.607 221.325 101.603C220.461 101.606 219.623 101.876 218.945 102.367C218.236 102.798 217.666 103.397 217.295 104.099C217.135 104.405 217.071 104.746 217.113 105.083C217.155 105.421 217.299 105.741 217.53 106.006C217.53 106.006 219.928 107.704 222.289 106.482C222.904 106.171 223.439 105.741 223.855 105.222C224.271 104.703 224.559 104.107 224.699 103.476L227.752 95.3484L238.258 98.784L235.903 105.112C235.149 104.546 233.178 103.267 231.003 104.325C230.904 104.37 230.805 104.427 230.706 104.484C230.607 104.54 230.502 104.608 230.403 104.676C230.152 104.847 229.917 105.037 229.699 105.242C228.592 106.21 228.463 107.036 228.605 107.591C228.634 107.724 228.682 107.854 228.747 107.976C228.806 108.106 228.887 108.226 228.988 108.332C228.988 108.332 230.941 109.719 233.067 109.085C233.302 109.011 233.529 108.918 233.746 108.808C234.625 108.357 235.332 107.672 235.773 106.844C235.941 106.523 236.071 106.187 236.163 105.842ZM227.943 94.7994L228.395 93.6108L238.95 96.9389L238.468 98.2293L227.943 94.7994Z"
              fill={color}
            />
            <g opacity="0.8">
              <path
                d="M211.467 82.07C211.483 81.5649 211.32 81.0686 211.003 80.655C210.477 79.9504 209.688 79.4454 208.785 79.2344C208.271 79.111 207.792 78.888 207.38 78.5804C206.969 78.2728 206.634 77.8878 206.399 77.4515V77.0949C206.398 77.0358 206.371 76.9797 206.325 76.9384C206.278 76.8972 206.217 76.8741 206.152 76.8741C206.087 76.8756 206.025 76.8999 205.979 76.942C205.933 76.9842 205.906 77.0409 205.905 77.1005L206.164 90.7185L205.991 90.6506H205.942L205.713 90.5883C204.551 90.2374 202.191 89.8752 200.67 91.9807C200.229 92.5482 199.93 93.1974 199.793 93.8824C199.784 93.9634 199.784 94.0449 199.793 94.1258C199.758 94.4599 199.827 94.7962 199.991 95.0963C200.155 95.3965 200.407 95.6485 200.72 95.8238C200.72 95.8238 203.612 96.6785 205.373 94.788C205.902 94.2069 206.26 93.5112 206.412 92.7674C206.588 92.4395 206.663 92.0733 206.628 91.709L206.449 82.087C206.979 82.8138 207.781 83.3374 208.704 83.5586C209.251 83.6886 209.757 83.931 210.186 84.2675C210.614 84.6039 210.953 85.0254 211.176 85.5C211.196 85.5256 211.222 85.5467 211.252 85.5614C211.282 85.5762 211.315 85.5842 211.349 85.5849H211.393C211.432 85.5759 211.468 85.5546 211.492 85.5248C211.517 85.4949 211.53 85.4582 211.529 85.4207L211.467 82.07Z"
                fill="white"
              />
              <path
                d="M124.051 117.032L124.119 117.383C124.13 117.441 124.165 117.493 124.217 117.528C124.269 117.563 124.334 117.578 124.397 117.57C124.429 117.565 124.46 117.554 124.488 117.539C124.515 117.523 124.539 117.502 124.558 117.478C124.577 117.454 124.591 117.427 124.598 117.398C124.606 117.369 124.607 117.339 124.601 117.309L122.061 103.889C122.12 103.907 122.18 103.92 122.241 103.929H122.296L122.531 103.963C123.767 104.144 126.14 104.172 127.277 101.874C127.623 101.253 127.811 100.568 127.827 99.8707C127.837 99.7899 127.837 99.7083 127.827 99.6274C127.808 99.2892 127.685 98.9629 127.472 98.6864C127.259 98.4098 126.964 98.1944 126.622 98.0652C126.622 98.0652 123.619 97.6237 122.197 99.7632C121.757 100.402 121.502 101.132 121.456 101.886C121.341 102.237 121.329 102.61 121.419 102.967L123.217 112.442L124.051 117.032Z"
                fill="white"
              />
              <path
                d="M80.0372 105.055C80.0856 104.551 79.9509 104.045 79.654 103.618C79.1703 102.89 78.4151 102.347 77.5281 102.089C77.0207 101.942 76.5539 101.697 76.1593 101.369C75.7648 101.042 75.4517 100.641 75.2415 100.193V99.8367C75.2415 99.7767 75.2155 99.7191 75.1691 99.6767C75.1228 99.6342 75.0599 99.6104 74.9943 99.6104C74.9288 99.6104 74.8659 99.6342 74.8195 99.6767C74.7732 99.7191 74.7471 99.7767 74.7471 99.8367L74.2095 113.421C74.1556 113.393 74.0999 113.368 74.0426 113.347H73.9932C73.9252 113.347 73.851 113.291 73.7707 113.268C72.6274 112.86 70.2852 112.385 68.6536 114.4C68.1789 114.945 67.8413 115.579 67.6648 116.256C67.6449 116.337 67.6304 116.418 67.6216 116.5C67.5653 116.835 67.6131 117.178 67.7595 117.489C67.906 117.8 68.1452 118.067 68.4497 118.26C68.4497 118.26 71.2863 119.256 73.1589 117.451C73.7428 116.911 74.1681 116.245 74.3949 115.515C74.5828 115.193 74.6785 114.833 74.673 114.468L74.8336 110.37C75.3136 110.964 75.9797 111.41 76.7433 111.649C77.2851 111.817 77.7787 112.094 78.1874 112.461C78.5961 112.827 78.9094 113.273 79.104 113.766C79.1215 113.793 79.1458 113.816 79.1748 113.833C79.2038 113.849 79.2367 113.859 79.2709 113.862H79.3141C79.3567 113.857 79.3955 113.837 79.4229 113.806C79.4503 113.776 79.4644 113.737 79.4625 113.698L79.5861 111.479C79.6156 110.974 79.4754 110.472 79.1844 110.042C78.6945 109.295 77.9296 108.733 77.0275 108.457C76.1524 108.188 75.4156 107.634 74.9572 106.9L75.0376 104.852C75.5232 105.603 76.2928 106.164 77.2006 106.431C77.7372 106.588 78.2276 106.855 78.6345 107.212C79.0415 107.569 79.3542 108.007 79.549 108.491C79.5665 108.516 79.5901 108.537 79.618 108.553C79.6458 108.569 79.6771 108.578 79.7097 108.581H79.7529C79.794 108.574 79.8314 108.555 79.8593 108.526C79.8872 108.497 79.9041 108.461 79.9074 108.423L80.0372 105.055Z"
                fill="white"
              />
              <path
                d="M109.509 102.91L108.273 93.7126L107.729 89.6601L107.686 89.462L95.6167 90.8147V91.0411L96.1667 95.088L97.4645 104.868L97.1802 104.812C96.6124 104.698 96.0289 104.664 95.4498 104.71C95.2231 104.733 94.9981 104.769 94.7762 104.818C94.5451 104.875 94.3197 104.951 94.1026 105.044C93.3295 105.396 92.7058 105.974 92.3289 106.685C91.8975 107.358 91.6712 108.124 91.6739 108.904C91.6743 109.243 91.7795 109.575 91.9775 109.862C92.1755 110.148 92.458 110.377 92.7924 110.523C92.7924 110.523 95.765 111.089 97.3038 109.046C97.7067 108.518 97.9805 107.918 98.1063 107.285C98.2322 106.652 98.2072 106.001 98.0331 105.378L96.6488 95.0427L107.736 93.8032L108.904 102.355C107.94 102.123 105.573 101.789 104.12 103.612C104.052 103.696 103.988 103.783 103.929 103.872C103.867 103.969 103.805 104.065 103.75 104.167C103.608 104.416 103.482 104.673 103.373 104.936C102.847 106.25 103.138 107.042 103.515 107.489C103.607 107.596 103.713 107.693 103.83 107.778C103.944 107.87 104.074 107.947 104.213 108.004C104.213 108.004 106.636 108.468 108.242 107.048C108.42 106.888 108.582 106.714 108.724 106.527C109.298 105.771 109.606 104.872 109.608 103.952C109.617 103.602 109.584 103.253 109.509 102.91Z"
                fill="white"
              />
              <path
                d="M236.163 105.842L238.833 98.7331L238.962 98.4048L239.457 97.086L240.909 93.2259L240.965 93.0278L229.464 89.4054L229.377 89.6205L227.937 93.4749L224.433 102.791L224.205 102.627C223.749 102.298 223.24 102.034 222.697 101.846C222.259 101.689 221.794 101.607 221.325 101.603C220.461 101.606 219.623 101.876 218.945 102.367C218.236 102.798 217.666 103.397 217.295 104.099C217.135 104.405 217.071 104.746 217.113 105.083C217.155 105.421 217.299 105.741 217.53 106.006C217.53 106.006 219.928 107.704 222.289 106.482C222.904 106.171 223.439 105.741 223.855 105.222C224.271 104.703 224.559 104.107 224.699 103.476L227.752 95.3484L238.258 98.784L235.903 105.112C235.149 104.546 233.178 103.267 231.003 104.325C230.904 104.37 230.805 104.427 230.706 104.484C230.607 104.54 230.502 104.608 230.403 104.676C230.152 104.847 229.917 105.037 229.699 105.242C228.592 106.21 228.463 107.036 228.605 107.591C228.634 107.724 228.682 107.854 228.747 107.976C228.806 108.106 228.887 108.226 228.988 108.332C228.988 108.332 230.941 109.719 233.067 109.085C233.302 109.011 233.529 108.918 233.746 108.808C234.625 108.357 235.332 107.672 235.773 106.844C235.941 106.523 236.071 106.187 236.163 105.842ZM227.943 94.7994L228.395 93.6108L238.95 96.9389L238.468 98.2293L227.943 94.7994Z"
                fill="white"
              />
            </g>
            <path
              d="M138.66 164.242C138.66 159.714 145.773 156.086 154.549 156.086C163.325 156.086 170.438 159.737 170.438 164.242H138.66Z"
              fill={color}
            />
            <path
              d="M138.66 164.242C138.66 159.714 145.773 156.086 154.549 156.086C163.325 156.086 170.438 159.737 170.438 164.242H138.66Z"
              fill={color}
            />
            <g opacity="0.5">
              <path
                d="M138.66 164.242C138.66 159.714 145.773 156.086 154.549 156.086C163.325 156.086 170.438 159.737 170.438 164.242H138.66Z"
                fill={color}
              />
              <path
                d="M138.66 164.242C138.66 159.714 145.773 156.086 154.549 156.086C163.325 156.086 170.438 159.737 170.438 164.242H138.66Z"
                fill="black"
              />
            </g>
            <path
              d="M171.402 164.242H137.425V173.649H171.402V164.242Z"
              fill={color}
            />
            <path
              opacity="0.6"
              d="M171.402 164.242H137.425V173.649H171.402V164.242Z"
              fill="white"
            />
            <path
              d="M138.363 173.649V219.444H139.865L141.157 173.649H138.363Z"
              fill="#263238"
            />
            <path
              d="M170.357 173.649V219.444H168.855L167.558 173.649H170.357Z"
              fill="#263238"
            />
            <path
              d="M162.422 173.649V219.444H160.921L159.629 173.649H162.422Z"
              fill="#263238"
            />
            <path
              d="M149.481 173.649V219.444H148.276L146.984 173.649H149.481Z"
              fill="#263238"
            />
            <path
              opacity="0.2"
              d="M162.422 173.649V219.444H160.921L159.629 173.649H162.422Z"
              fill="white"
            />
            <path
              opacity="0.2"
              d="M149.481 173.649V219.444H148.276L146.984 173.649H149.481Z"
              fill="white"
            />
            <path
              d="M153.826 213.716C149.148 213.749 144.473 213.509 139.828 212.997C134.075 212.29 133.296 211.367 133.296 210.552C133.296 209.737 134.075 208.814 139.828 208.107C144.473 207.595 149.148 207.355 153.826 207.388C158.506 207.355 163.183 207.595 167.83 208.107C173.583 208.814 174.356 209.743 174.356 210.552C174.356 211.361 173.583 212.29 167.83 212.997C163.183 213.509 158.506 213.749 153.826 213.716ZM135.341 210.552C136.868 211.288 143.703 212.222 153.813 212.222C163.924 212.222 170.765 211.288 172.286 210.552C170.765 209.816 163.93 208.882 153.813 208.882C143.697 208.882 136.88 209.816 135.341 210.552Z"
              fill={color}
            />
            <g opacity="0.6">
              <path
                d="M153.709 213.716C149.029 213.749 144.351 213.509 139.705 212.997C133.951 212.29 133.179 211.367 133.179 210.552C133.179 209.737 133.951 208.814 139.705 208.107C144.351 207.595 149.029 207.355 153.709 207.388C158.389 207.355 163.066 207.595 167.713 208.107C173.466 208.814 174.239 209.743 174.239 210.552C174.239 211.361 173.466 212.29 167.713 212.997C163.066 213.509 158.389 213.749 153.709 213.716ZM135.237 210.552C136.763 211.288 143.598 212.222 153.709 212.222C163.819 212.222 170.654 211.288 172.181 210.552C170.66 209.816 163.825 208.882 153.709 208.882C143.592 208.882 136.757 209.816 135.237 210.552Z"
                fill="white"
              />
            </g>
            <path
              d="M152.35 110.664C152.318 110.694 152.289 110.726 152.263 110.76H152.146L152.35 110.664Z"
              fill="#263238"
            />
            <path
              d="M164.455 119.635C164.455 119.635 164.412 119.635 164.4 119.607L164.455 119.635Z"
              fill="#263238"
            />
            <path
              d="M145.23 80.1173C144.994 80.3501 144.828 80.6347 144.748 80.9437C144.712 81.0984 144.732 81.2597 144.806 81.4027C144.88 81.5458 145.002 81.6629 145.156 81.7361C145.328 81.7803 145.51 81.781 145.683 81.7382C145.856 81.6954 146.013 81.6105 146.138 81.4927C147.047 80.7909 147.306 79.6192 147.516 78.5495C147.516 78.5495 146.447 79.0646 146.361 79.1155C145.946 79.4123 145.567 79.7481 145.23 80.1173Z"
              fill="#263238"
            />
            <path
              d="M150.959 139.491C149.58 143.534 149.334 147.829 150.242 151.982C152.164 160.008 173.856 161.14 183.108 162.318C178.905 176.62 169.376 206.109 169.376 206.109L176.013 206.952C176.013 206.952 194.732 175.505 197.76 161.31C200.541 148.36 168.096 141.659 168.096 141.659L150.959 139.491Z"
              fill={color}
            />
            <path
              d="M176.309 207.071C177.909 208.441 179.665 209.901 184.436 211.186C185.492 211.463 185.356 213.773 182.804 213.58C180.252 213.388 176.544 212.924 174.702 212.782C171.699 212.55 170.543 212.595 168.17 212.454C167.885 212.428 167.624 212.301 167.441 212.099C167.258 211.898 167.169 211.639 167.193 211.378L167.978 206.46C167.984 206.408 168.002 206.358 168.029 206.313C168.057 206.267 168.095 206.227 168.14 206.196C168.185 206.164 168.237 206.141 168.292 206.128C168.347 206.115 168.404 206.112 168.46 206.12L175.289 206.641C175.671 206.679 176.029 206.83 176.309 207.071Z"
              fill="#263238"
            />
            <path
              d="M177.471 210.688C177.749 210.382 177.65 209.556 177.471 208.871C177.434 208.696 177.39 208.531 177.353 208.39C177.267 208.056 177.187 207.796 177.162 207.728C177.153 207.704 177.138 207.683 177.117 207.667C177.096 207.651 177.071 207.641 177.044 207.637C177.004 207.642 176.966 207.655 176.933 207.677V207.716C176.722 208.199 176.557 208.697 176.439 209.205C176.306 209.622 176.337 210.068 176.525 210.467C176.587 210.559 176.672 210.636 176.773 210.692C176.874 210.747 176.988 210.779 177.106 210.784C177.171 210.797 177.238 210.795 177.302 210.778C177.365 210.761 177.423 210.73 177.471 210.688ZM176.729 210.371C176.556 209.895 176.569 209.379 176.766 208.911C176.839 208.646 176.928 208.385 177.032 208.13C177.081 208.271 177.125 208.424 177.162 208.571C177.317 209.139 177.383 209.724 177.36 210.309C177.354 210.387 177.321 210.463 177.267 210.524C177.249 210.545 177.225 210.562 177.198 210.572C177.171 210.582 177.141 210.585 177.112 210.58C177.038 210.574 176.966 210.551 176.903 210.515C176.84 210.479 176.787 210.429 176.748 210.371H176.729Z"
              fill={color}
            />
            <path
              d="M179.115 209.42C179.158 209.307 179.174 209.187 179.162 209.068C179.15 208.949 179.111 208.834 179.047 208.729C178.779 208.375 178.406 208.098 177.972 207.931C177.697 207.805 177.409 207.705 177.113 207.631C177.092 207.622 177.068 207.616 177.045 207.616C177.021 207.616 176.998 207.622 176.977 207.631V207.66V207.699V207.745C177.055 207.991 177.165 208.229 177.304 208.452C177.378 208.577 177.459 208.701 177.545 208.831C177.904 209.329 178.361 209.765 178.719 209.771C178.812 209.799 179.01 209.754 179.115 209.42ZM177.471 208.288C177.403 208.158 177.341 208.028 177.292 207.909C177.452 207.949 177.609 208 177.761 208.062C178.179 208.235 178.546 208.497 178.831 208.826C178.909 208.957 178.926 209.112 178.88 209.256V209.318C178.806 209.533 178.713 209.528 178.682 209.522C178.379 209.556 177.811 208.916 177.471 208.288Z"
              fill={color}
            />
            <path
              d="M141.522 141.675C141.522 141.675 109.083 148.377 111.858 161.31C114.905 175.488 133.624 206.935 133.624 206.935L140.286 206.109C140.286 206.109 130.756 176.62 126.554 162.318C135.824 161.14 157.497 160.008 159.419 151.982C160.327 147.829 160.081 143.534 158.702 139.491L141.522 141.675Z"
              fill={color}
            />
            <path
              d="M134.353 206.635L141.151 206.115C141.207 206.107 141.264 206.109 141.32 206.122C141.375 206.135 141.426 206.158 141.471 206.19C141.516 206.222 141.554 206.261 141.582 206.307C141.609 206.352 141.627 206.402 141.633 206.454L142.418 211.373C142.442 211.634 142.353 211.893 142.17 212.094C141.988 212.295 141.726 212.422 141.441 212.448C139.068 212.59 137.913 212.544 134.909 212.776C133.055 212.918 129.347 213.376 126.807 213.574C124.267 213.773 124.119 211.458 125.176 211.18C129.947 209.873 131.702 208.435 133.302 207.065C133.59 206.818 133.96 206.667 134.353 206.635Z"
              fill="#263238"
            />
            <path
              d="M160.34 106.68C160.062 105.694 160.223 104.648 160.788 103.771C161.352 102.894 162.274 102.259 163.35 102.005C164.426 101.75 165.568 101.898 166.525 102.415C167.483 102.931 168.177 103.775 168.454 104.761V104.84C169.072 106.866 169.777 109.017 170.512 111.111C171.248 113.206 172.033 115.277 172.873 117.298C173.702 119.281 174.642 121.223 175.691 123.116C176.186 124 176.737 124.856 177.341 125.68C177.843 126.41 178.439 127.083 179.115 127.684L178.787 127.458C178.496 127.276 178.161 127.162 177.811 127.124H177.669C177.9 127.128 178.132 127.102 178.355 127.044C179.101 126.876 179.83 126.653 180.536 126.377C182.081 125.811 183.707 125.063 185.332 124.294C186.957 123.524 188.57 122.709 190.208 121.894C191.846 121.079 193.477 120.196 195.09 119.386H195.127C195.529 119.185 195.992 119.109 196.445 119.172C196.899 119.234 197.317 119.431 197.636 119.732C198.44 120.49 199.422 121.724 198.588 122.465C197.173 123.705 194.744 124.843 193.249 125.969C191.753 127.095 190.22 128.182 188.632 129.229C187.001 130.307 185.303 131.297 183.546 132.195C182.552 132.683 181.519 133.101 180.456 133.446C179.777 133.673 179.072 133.829 178.355 133.91C177.84 133.967 177.319 133.967 176.804 133.91C175.981 133.83 175.191 133.572 174.498 133.157L174.282 133.01L174.177 132.925C172.988 132.019 171.92 130.987 170.994 129.852C170.154 128.826 169.38 127.757 168.677 126.648C167.343 124.548 166.166 122.368 165.154 120.122C164.141 117.932 163.257 115.707 162.454 113.477C162.058 112.345 161.681 111.247 161.317 110.081C160.952 108.915 160.631 107.817 160.309 106.623L160.34 106.68Z"
              fill="#263238"
            />
            <path
              d="M196.721 122.613L198.575 122.465C198.575 122.465 197.834 119.069 196.462 117.903C195.746 118.524 195.123 119.228 194.608 119.998C194.571 121.973 196.721 122.613 196.721 122.613Z"
              fill="white"
            />
            <path
              d="M196.827 118.486C196.515 118.68 196.293 118.972 196.203 119.306C196.112 119.641 196.161 119.994 196.339 120.298L196.901 121.311C197.026 121.536 197.215 121.726 197.447 121.861C197.68 121.997 197.948 122.073 198.223 122.081L203.1 122.262L206.023 119.471C206.215 119.292 206.337 119.058 206.368 118.808C206.399 118.558 206.339 118.305 206.196 118.09L205.182 116.562C205.028 116.324 204.784 116.145 204.496 116.061C204.207 115.976 203.895 115.991 203.619 116.104L201.487 116.953C201.487 116.953 198.409 117.536 196.827 118.486Z"
              fill="#F9BBB2"
            />
            <path
              d="M163.448 100.946C155.661 100.108 147.794 100.108 140.007 100.946H139.89C138.902 101.105 138.022 101.616 137.443 102.366C136.864 103.117 136.633 104.047 136.8 104.953C138.932 116.636 141.126 128.595 140.941 141.33L168.096 141.562C170.679 123.184 169.412 110.998 168.442 105.338C168.23 104.214 167.632 103.181 166.733 102.39C165.835 101.6 164.683 101.094 163.448 100.946Z"
              fill="#263238"
            />
            <path
              d="M113.013 135.84C110.85 128.29 109.034 106.329 140.008 113.794C142.64 114.428 145.273 115.028 147.733 113.964L150.205 112.9C156.144 110.466 162.806 112.334 164.264 117.428L167.533 128.839C168.986 133.933 164.19 138.568 157.707 139.106C157.707 139.106 156.471 139.196 154.982 139.293C152.269 139.468 150.297 141.177 148.344 142.909C125.343 163.348 115.207 143.521 113.013 135.84Z"
              fill={color}
            />
            <path
              opacity="0.6"
              d="M113.013 135.84C110.85 128.29 109.034 106.329 140.008 113.794C142.64 114.428 145.273 115.028 147.733 113.964L150.205 112.9C156.144 110.466 162.806 112.334 164.264 117.428L167.533 128.839C168.986 133.933 164.19 138.568 157.707 139.106C157.707 139.106 156.471 139.196 154.982 139.293C152.269 139.468 150.297 141.177 148.344 142.909C125.343 163.348 115.207 143.521 113.013 135.84Z"
              fill="white"
            />
            <path
              opacity="0.5"
              d="M143.339 130.989L144.111 133.502C144.35 134.28 144.772 135 145.348 135.612C145.924 136.224 146.639 136.712 147.444 137.043C148.248 137.374 149.122 137.539 150.004 137.527C150.886 137.515 151.754 137.325 152.547 136.972C154.44 136.151 156.229 135.141 157.88 133.961C161.158 131.596 163.97 128.734 166.193 125.499L143.339 130.989Z"
              fill="black"
            />
            <path
              d="M155.247 125.878C155.5 126.763 155.461 127.695 155.134 128.559C154.808 129.423 154.21 130.179 153.416 130.731C152.621 131.284 151.665 131.608 150.669 131.664C149.673 131.719 148.682 131.503 147.82 131.043C146.958 130.583 146.264 129.899 145.826 129.078C145.388 128.257 145.225 127.335 145.359 126.429C145.492 125.524 145.916 124.675 146.576 123.989C147.236 123.304 148.103 122.813 149.067 122.579C149.71 122.423 150.379 122.385 151.038 122.467C151.696 122.549 152.33 122.749 152.904 123.055C153.478 123.362 153.981 123.769 154.383 124.253C154.785 124.738 155.079 125.29 155.247 125.878Z"
              fill="#263238"
            />
            <path
              d="M202.741 111.437L154.431 123.028L156.075 128.777L204.385 117.186L202.741 111.437Z"
              fill={color}
            />
            <path
              d="M204.514 117.683L202.673 111.264L215.465 107.353L217.752 115.345L204.514 117.683Z"
              fill={color}
            />
            <path
              opacity="0.2"
              d="M204.514 117.683L202.673 111.264L215.465 107.353L217.752 115.345L204.514 117.683Z"
              fill="white"
            />
            <path
              d="M131.233 125.959L127.341 126.892L130.489 137.898L134.381 136.964L131.233 125.959Z"
              fill="white"
            />
            <path
              d="M202.971 112.292L129.771 129.892L129.85 130.166L203.05 112.565L202.971 112.292Z"
              fill="white"
            />
            <path
              d="M203.417 114.629L130.007 131.957L130.084 132.231L203.494 114.903L203.417 114.629Z"
              fill="white"
            />
            <path
              d="M203.727 114.943L130.518 132.508L130.596 132.782L203.806 115.217L203.727 114.943Z"
              fill="white"
            />
            <path
              d="M204.163 117.27L130.753 134.598L130.83 134.872L204.24 117.544L204.163 117.27Z"
              fill="white"
            />
            <path
              d="M213.871 109.844C213.868 109.966 213.82 110.084 213.737 110.18C213.653 110.276 213.538 110.344 213.408 110.375C213.279 110.406 213.142 110.397 213.018 110.35C212.894 110.303 212.79 110.221 212.722 110.115C212.673 110.04 212.644 109.955 212.637 109.867C212.63 109.78 212.646 109.692 212.683 109.611C212.719 109.53 212.776 109.457 212.848 109.4C212.921 109.342 213.006 109.301 213.099 109.279C213.192 109.257 213.289 109.255 213.383 109.273C213.477 109.292 213.565 109.33 213.639 109.385C213.714 109.439 213.774 109.509 213.815 109.589C213.855 109.669 213.874 109.756 213.871 109.844Z"
              fill="white"
            />
            <path
              d="M215.101 114.145C215.135 114.284 215.11 114.429 215.032 114.551C214.954 114.674 214.829 114.765 214.681 114.807C214.529 114.838 214.37 114.814 214.236 114.742C214.102 114.669 214.003 114.553 213.958 114.417C213.938 114.349 213.932 114.277 213.943 114.207C213.953 114.137 213.978 114.069 214.017 114.008C214.056 113.946 214.109 113.893 214.171 113.851C214.233 113.808 214.303 113.777 214.378 113.76C214.53 113.73 214.688 113.753 214.822 113.824C214.956 113.895 215.055 114.01 215.101 114.145Z"
              fill="white"
            />
            <path
              d="M206.807 111.536C206.803 111.657 206.755 111.774 206.673 111.869C206.59 111.964 206.476 112.032 206.348 112.063C206.22 112.094 206.084 112.087 205.961 112.042C205.838 111.997 205.734 111.917 205.664 111.813C205.669 111.692 205.716 111.575 205.799 111.48C205.881 111.386 205.995 111.318 206.123 111.286C206.252 111.255 206.387 111.263 206.51 111.308C206.634 111.353 206.738 111.433 206.807 111.536Z"
              fill="white"
            />
            <path
              d="M207.889 115.317C207.924 115.426 207.923 115.542 207.886 115.651C207.848 115.759 207.776 115.855 207.678 115.925C207.58 115.995 207.461 116.037 207.337 116.044C207.213 116.052 207.09 116.025 206.983 115.967C206.876 115.909 206.79 115.823 206.737 115.72C206.685 115.617 206.667 115.502 206.687 115.39C206.706 115.277 206.763 115.173 206.848 115.09C206.933 115.008 207.044 114.95 207.166 114.926C207.318 114.896 207.477 114.92 207.611 114.992C207.745 115.064 207.844 115.18 207.889 115.317Z"
              fill="white"
            />
            <path
              d="M136.869 102.016C137.807 101.53 138.913 101.394 139.958 101.635C141.003 101.876 141.906 102.476 142.481 103.31C143.057 104.145 143.259 105.15 143.047 106.118C142.835 107.085 142.225 107.941 141.343 108.508C138.179 110.421 134.922 112.47 131.838 114.575C128.772 116.575 125.879 118.789 123.186 121.198C122.877 121.486 122.599 121.764 122.309 122.064L121.907 122.499C122.124 122.194 122.258 121.844 122.296 121.481C122.327 121.167 122.296 120.85 122.204 120.547C122.166 120.415 122.117 120.286 122.055 120.162C122.006 120.071 121.95 119.975 121.888 119.884C121.801 119.735 121.673 119.609 121.518 119.517C121.462 119.517 121.678 119.692 121.963 119.828C122.688 120.157 123.443 120.429 124.218 120.643C125.077 120.898 125.973 121.135 126.888 121.356C130.559 122.222 134.483 122.924 138.327 123.62L138.364 123.648C138.863 123.739 139.309 123.994 139.617 124.365C139.926 124.736 140.076 125.197 140.04 125.661C140.003 126.126 139.783 126.563 139.42 126.89C139.057 127.216 138.576 127.41 138.068 127.435C133.947 127.667 129.813 127.583 125.708 127.186C124.663 127.078 123.606 126.942 122.519 126.761C121.349 126.562 120.199 126.272 119.083 125.895C118.32 125.64 117.599 125.292 116.938 124.86C116.385 124.483 115.91 124.019 115.535 123.49C115.395 123.302 115.273 123.103 115.171 122.896C115.054 122.668 114.961 122.431 114.893 122.188C114.745 121.666 114.707 121.122 114.781 120.586C114.899 119.768 115.239 118.989 115.77 118.322L116.295 117.683C116.648 117.264 117 116.845 117.365 116.46C118.767 114.957 120.274 113.539 121.876 112.215C123.402 110.885 125.003 109.657 126.628 108.491C128.254 107.325 129.885 106.199 131.572 105.123C133.26 104.048 134.922 103.006 136.751 101.993L136.869 102.016Z"
              fill="#263238"
            />
            <path
              d="M147.084 100.488C146.958 100.644 146.894 100.836 146.903 101.031C146.912 101.225 146.993 101.411 147.133 101.557C148.172 102.616 150.934 104.778 156.224 104.778C159.827 104.778 160.247 103.601 159.889 102.554C159.716 102.1 159.43 101.69 159.055 101.354C158.68 101.018 158.225 100.766 157.726 100.618C155.106 99.6953 154.636 97.8784 154.846 95.8917C154.898 95.3372 154.993 94.7867 155.13 94.2447L150.65 90.8487L147.374 88.3413C148.246 91.7316 149.191 97.7482 147.084 100.488Z"
              fill="#F9BBB2"
            />
            <path
              d="M157.782 105.304C157.111 105.24 156.462 105.048 155.878 104.738C154.987 105.191 154.027 105.517 153.029 105.706C153.169 105.263 153.411 104.853 153.738 104.502C154.065 104.152 154.47 103.869 154.927 103.674C155.311 103.901 155.646 104.191 155.915 104.529C155.987 104.213 156.145 103.92 156.373 103.674C156.373 103.674 157.738 104.218 157.782 105.304Z"
              fill={color}
            />
            <path
              d="M147.183 99.4236C146.343 99.5463 145.525 99.7769 144.754 100.108C144.34 105.344 153.11 107.353 153.11 107.353C153.346 106.007 153.974 104.744 154.927 103.697C149.068 102.559 147.183 99.4236 147.183 99.4236Z"
              fill="white"
            />
            <path
              d="M156.101 99.7066C156.89 99.7841 157.668 99.9339 158.424 100.154C162.8 103.589 158.307 107.353 158.307 107.353C157.998 106.008 157.333 104.753 156.373 103.697C159.011 101.88 156.101 99.7066 156.101 99.7066Z"
              fill="white"
            />
            <path
              opacity="0.2"
              d="M150.693 90.826C150.648 91.3472 150.669 91.8713 150.754 92.3882C150.965 93.9504 153.189 95.5748 154.864 95.8804C154.916 95.324 155.011 94.7717 155.148 94.2277L150.693 90.826Z"
              fill="black"
            />
            <path
              d="M146.138 79.806C146.403 78.991 146.841 78.2317 147.426 77.5716C148.011 76.9114 148.733 76.3634 149.549 75.9587C150.366 75.554 151.262 75.3005 152.185 75.2129C153.109 75.1252 154.042 75.2051 154.932 75.4478C160.451 76.8119 162.385 83.9378 161.977 88.4035C161.895 89.568 161.466 90.6895 160.738 91.6469C160.01 92.6042 159.009 93.3608 157.845 93.8348C156.681 94.3088 155.397 94.482 154.133 94.3357C152.869 94.1895 151.673 93.7294 150.674 93.0051C147.584 90.809 147.176 87.9507 146.033 83.1454C145.749 82.043 145.785 80.8916 146.138 79.806Z"
              fill="#F9BBB2"
            />
            <path
              d="M145.915 84.7642C147.287 84.4529 148.752 85.8962 149.339 86.9942C150 88.2054 149.549 89.4563 148.06 89.2129C146.915 89.0097 145.89 88.43 145.18 87.5828C144.302 86.5018 144.55 85.0811 145.915 84.7642Z"
              fill="#F9BBB2"
            />
            <path
              d="M155.415 82.8228C155.705 82.7605 155.989 83.0548 156.033 83.451C156.076 83.8472 155.921 84.1925 155.637 84.2208C155.353 84.2491 155.075 83.9604 155.019 83.5642C154.963 83.168 155.118 82.885 155.415 82.8228Z"
              fill="#263238"
            />
            <path
              d="M160.309 82.2285C160.593 82.1945 160.883 82.4889 160.927 82.8851C160.97 83.2813 160.809 83.5926 160.525 83.6548C160.241 83.7171 159.969 83.4228 159.907 83.0266C159.845 82.6304 160.018 82.2568 160.309 82.2285Z"
              fill="#263238"
            />
            <path
              d="M158.733 86.3829C159.036 86.4408 159.349 86.4369 159.65 86.3713C159.952 86.3058 160.233 86.1803 160.476 86.0037C159.658 85.1475 158.968 84.1959 158.424 83.1737L158.733 86.3829Z"
              fill="#ED847E"
            />
            <path
              d="M153.325 81.2154C153.531 80.9945 153.792 80.8221 154.085 80.7128C154.379 80.6034 154.697 80.5603 155.012 80.5871C155.046 80.5928 155.08 80.5922 155.113 80.5856C155.146 80.5789 155.177 80.5662 155.205 80.5483C155.233 80.5304 155.256 80.5075 155.274 80.4811C155.292 80.4547 155.304 80.4253 155.309 80.3946C155.318 80.3326 155.301 80.2697 155.26 80.2191C155.22 80.1685 155.16 80.1341 155.093 80.123C154.693 80.0836 154.289 80.1341 153.915 80.2702C153.541 80.4064 153.209 80.6239 152.948 80.9041C152.925 80.9256 152.906 80.9512 152.893 80.9794C152.881 81.0075 152.874 81.0377 152.874 81.0682C152.874 81.0987 152.881 81.1289 152.893 81.157C152.906 81.1852 152.925 81.2108 152.948 81.2323C152.981 81.2553 153.019 81.2708 153.06 81.2776C153.105 81.2932 153.156 81.2956 153.203 81.2845C153.25 81.2734 153.293 81.2492 153.325 81.2154Z"
              fill="#263238"
            />
            <path
              d="M161.786 80.938C161.807 80.9143 161.823 80.887 161.833 80.8576C161.842 80.8282 161.846 80.7973 161.842 80.7668C161.839 80.7363 161.829 80.7067 161.813 80.6797C161.797 80.6527 161.775 80.629 161.749 80.6098C161.444 80.3568 161.077 80.1737 160.68 80.0753C160.282 79.9769 159.865 79.9661 159.462 80.0438C159.398 80.0621 159.345 80.1024 159.312 80.1562C159.28 80.21 159.272 80.2731 159.289 80.3324C159.309 80.391 159.353 80.4402 159.412 80.4697C159.471 80.4993 159.54 80.5069 159.604 80.4909C159.931 80.4289 160.268 80.4375 160.59 80.5161C160.912 80.5948 161.209 80.7412 161.458 80.9437C161.49 80.9684 161.529 80.9855 161.57 80.9934C161.611 81.0013 161.653 80.9998 161.693 80.989C161.728 80.9786 161.76 80.9612 161.786 80.938Z"
              fill="#263238"
            />
            <path
              d="M139.05 123.15L136.022 124.633C134.742 126.665 139.223 128.595 139.223 128.595L141.695 130.712C142.013 130.985 142.417 131.158 142.849 131.206C143.282 131.254 143.72 131.174 144.099 130.978L144.748 130.655C144.982 130.532 145.187 130.368 145.352 130.172C145.516 129.976 145.637 129.753 145.707 129.514C145.777 129.275 145.795 129.026 145.76 128.781C145.725 128.536 145.637 128.3 145.502 128.086L143.598 125.046L139.05 123.15Z"
              fill="#F9BBB2"
            />
            <path
              d="M135.614 127.299L137.325 128.024C137.325 128.024 138.802 124.333 138.37 122.562C137.337 122.84 136.347 123.235 135.422 123.739C135.055 124.28 134.876 124.91 134.91 125.546C134.945 126.183 135.19 126.794 135.614 127.299Z"
              fill="white"
            />
            <path
              d="M148.134 73.7894C150.39 72.8272 153.01 72.9178 155.488 73.1725C156.652 73.3523 157.836 73.3942 159.011 73.297C159.599 73.2446 160.167 73.0731 160.673 72.7951C161.18 72.5171 161.613 72.1395 161.94 71.6896C162.75 73.7328 162.558 76.2742 160.834 77.7514C159.549 78.8438 157.682 79.1551 155.927 79.1155C154.172 79.0759 152.442 78.7419 150.693 78.7476C148.555 78.7476 145.1 81.4814 144.655 78.7872C144.309 76.6874 146.175 74.6328 148.134 73.7894Z"
              fill="#263238"
            />
            <path
              d="M150.68 79.7438C150.68 79.6685 150.66 79.5944 150.623 79.5273C150.586 79.4601 150.532 79.4018 150.466 79.3568C150.401 79.3118 150.324 79.2815 150.243 79.2681C150.162 79.2547 150.079 79.2587 150 79.2797L145.841 79.7325C145.001 79.9758 144.506 81.0116 144.247 81.7814C143.899 82.7543 143.663 83.7577 143.542 84.7755C143.345 86.2392 143.722 87.7195 144.605 88.9526C145.146 89.4872 145.598 90.0918 145.946 90.7468C146.2 91.4373 145.823 92.3995 145.026 92.4448C149.197 94.3692 148.468 90.8996 148.579 89.2413C147.145 89.2016 146.033 88.6187 145.736 87.3282C145.677 86.8948 145.725 86.4546 145.878 86.0409C146.031 85.6271 146.285 85.2506 146.62 84.9397C146.78 84.7584 147.01 84.6395 147.262 84.6069C147.514 84.5744 147.771 84.6306 147.98 84.7642C147.899 86.3433 148.857 88.0357 149.673 89.428L150.588 88.0979C149.487 86.6829 147.936 84.1359 149.834 83.4115C150.668 83.0832 150.674 80.938 150.68 79.7438Z"
              fill="#263238"
            />
            <path
              d="M159.394 87.8942C159.286 88.7224 159.234 89.5561 159.24 90.3902C159.11 90.3902 158.974 90.4355 158.844 90.4468C157.064 90.6732 156.15 90.1185 155.674 89.4337C155.445 89.0872 155.293 88.7024 155.229 88.3017C155.184 88.0701 155.168 87.8348 155.179 87.5999C156.211 88.1093 157.892 88.047 158.782 87.9678C159.159 87.9338 159.394 87.8942 159.394 87.8942Z"
              fill="#2E353A"
            />
            <path
              d="M158.782 87.9621L158.751 88.3243C158.743 88.4287 158.696 88.5271 158.617 88.6026C158.538 88.6781 158.432 88.7258 158.319 88.7375C156.978 88.8733 155.97 88.7771 155.383 88.4092C155.329 88.372 155.284 88.3244 155.252 88.2696C155.22 88.2148 155.201 88.1543 155.198 88.0923C155.181 87.9266 155.181 87.7599 155.198 87.5942C156.211 88.1036 157.892 88.0413 158.782 87.9621Z"
              fill="white"
            />
            <path
              d="M158.826 90.4468C157.046 90.6732 156.131 90.1185 155.655 89.4336C156.34 89.363 157.034 89.4287 157.688 89.6261C158.155 89.7793 158.554 90.0674 158.826 90.4468Z"
              fill="#DE5753"
            />
            <path
              d="M167.589 205.945L177.996 207.609L179.547 204.767L167.169 202.792L167.589 205.945Z"
              fill={color}
            />
            <path
              opacity="0.6"
              d="M167.589 205.945L177.996 207.609L179.547 204.767L167.169 202.792L167.589 205.945Z"
              fill="white"
            />
            <path
              d="M132.376 208.43C132.66 208.09 132.907 207.756 133.025 207.586C133.052 207.552 133.077 207.516 133.099 207.479C133.099 207.479 133.099 207.479 133.099 207.445C133.114 207.408 133.114 207.368 133.099 207.332C133.085 207.315 133.068 207.301 133.047 207.292C133.027 207.283 133.004 207.279 132.981 207.281C132.961 207.276 132.94 207.276 132.92 207.281C132.814 207.281 132.691 207.332 132.561 207.371C131.752 207.586 130.707 207.937 130.41 208.464L130.355 208.52C130.303 208.648 130.287 208.785 130.308 208.92C130.33 209.054 130.388 209.182 130.478 209.29C130.528 209.361 130.596 209.42 130.677 209.461C130.758 209.502 130.85 209.523 130.942 209.522C131.35 209.511 131.925 208.962 132.376 208.43ZM130.905 209.256C130.864 209.252 130.824 209.24 130.79 209.219C130.755 209.198 130.727 209.17 130.707 209.137C130.647 209.066 130.607 208.982 130.592 208.893C130.577 208.803 130.587 208.712 130.621 208.628C130.781 208.282 131.504 207.892 132.629 207.648C132.122 208.373 131.356 209.262 130.905 209.256Z"
              fill={color}
            />
            <path
              d="M133.537 209.765C133.816 209.165 133.426 208.146 133.21 207.648C133.167 207.558 133.142 207.496 133.117 207.45C133.117 207.405 133.08 207.377 133.074 207.377C133.069 207.354 133.058 207.334 133.04 207.317C133.022 207.301 133 207.29 132.975 207.286C132.955 207.281 132.934 207.281 132.913 207.286L132.852 207.331C132.852 207.382 132.796 207.433 132.771 207.49V207.547C132.621 207.83 132.507 208.128 132.431 208.435C132.228 208.964 132.239 209.543 132.462 210.065C132.539 210.152 132.644 210.214 132.762 210.242C132.88 210.269 133.005 210.261 133.117 210.218C133.218 210.173 133.307 210.11 133.379 210.032C133.451 209.954 133.505 209.863 133.537 209.765ZM132.975 209.929C132.79 209.992 132.716 209.929 132.672 209.884C132.394 209.55 132.604 208.39 132.938 207.739C133.173 208.265 133.507 209.177 133.284 209.663C133.279 209.676 133.279 209.69 133.284 209.703C133.253 209.759 133.21 209.808 133.157 209.847C133.103 209.886 133.041 209.914 132.975 209.929Z"
              fill={color}
            />
            <path
              d="M131.745 207.581L142.232 206.454L142.838 203.324L130.354 204.666L131.745 207.581Z"
              fill={color}
            />
            <path
              opacity="0.6"
              d="M131.745 207.581L142.232 206.454L142.838 203.324L130.354 204.666L131.745 207.581Z"
              fill="white"
            />
            <path
              d="M180.065 95.3846C182.821 92.8604 182.821 88.7678 180.065 86.2435C177.309 83.7193 172.84 83.7193 170.084 86.2435C167.328 88.7678 167.328 92.8604 170.084 95.3846C172.84 97.9089 177.309 97.9089 180.065 95.3846Z"
              fill={color}
            />
            <path
              opacity="0.6"
              d="M180.065 95.3846C182.821 92.8604 182.821 88.7678 180.065 86.2435C177.309 83.7193 172.84 83.7193 170.084 86.2435C167.328 88.7678 167.328 92.8604 170.084 95.3846C172.84 97.9089 177.309 97.9089 180.065 95.3846Z"
              fill="white"
            />
            <path
              d="M175.074 97.8671C173.556 97.8671 172.072 97.4548 170.81 96.6823C169.547 95.9099 168.564 94.812 167.983 93.5275C167.402 92.243 167.25 90.8295 167.546 89.4659C167.842 88.1023 168.573 86.8497 169.647 85.8666C170.72 84.8835 172.088 84.2139 173.577 83.9427C175.065 83.6715 176.609 83.8107 178.011 84.3427C179.414 84.8748 180.613 85.7758 181.456 86.9318C182.299 88.0879 182.75 89.447 182.75 90.8373C182.748 92.7013 181.939 94.4885 180.5 95.8065C179.061 97.1245 177.109 97.8656 175.074 97.8671ZM175.074 84.9396C173.8 84.9396 172.555 85.2855 171.496 85.9336C170.437 86.5816 169.612 87.5027 169.125 88.5804C168.637 89.6581 168.51 90.8439 168.758 91.9879C169.007 93.132 169.62 94.1828 170.521 95.0077C171.421 95.8325 172.569 96.3942 173.818 96.6217C175.067 96.8493 176.362 96.7325 177.538 96.2861C178.715 95.8397 179.721 95.0838 180.428 94.1139C181.136 93.1441 181.514 92.0038 181.514 90.8373C181.512 89.2736 180.833 87.7744 179.626 86.6687C178.418 85.563 176.781 84.9411 175.074 84.9396Z"
              fill="#263238"
            />
            <path
              d="M182.929 91.9297C182.929 92.5398 182.731 93.1362 182.361 93.6434C181.991 94.1507 181.465 94.5461 180.849 94.7796C180.234 95.013 179.557 95.0741 178.903 94.9551C178.25 94.8361 177.65 94.5423 177.179 94.1109C176.708 93.6795 176.387 93.1298 176.257 92.5315C176.127 91.9331 176.194 91.3129 176.449 90.7492C176.704 90.1856 177.135 89.7038 177.689 89.3648C178.243 89.0259 178.894 88.845 179.56 88.845C180.454 88.845 181.31 89.17 181.942 89.7485C182.574 90.327 182.929 91.1116 182.929 91.9297Z"
              fill="#263238"
            />
            <path
              d="M179.567 102.503C178.949 102.503 178.341 102.365 177.795 102.099C177.25 101.834 176.784 101.449 176.439 100.98L177.453 100.335C177.684 100.652 177.997 100.912 178.365 101.091C178.733 101.27 179.144 101.362 179.56 101.359C179.977 101.364 180.388 101.272 180.755 101.091C181.123 100.911 181.435 100.649 181.662 100.329C181.707 100.267 181.765 100.214 181.832 100.172C181.9 100.131 181.975 100.102 182.055 100.087C182.135 100.072 182.217 100.072 182.297 100.086C182.377 100.101 182.453 100.129 182.521 100.171C182.588 100.212 182.647 100.265 182.692 100.327C182.737 100.389 182.769 100.458 182.785 100.531C182.801 100.604 182.801 100.68 182.786 100.753C182.77 100.826 182.739 100.895 182.694 100.957C182.349 101.429 181.883 101.815 181.336 102.083C180.79 102.35 180.18 102.491 179.56 102.491L179.567 102.503Z"
              fill="#263238"
            />
            <path
              d="M176.415 92.6146L182.719 92.6655L182.101 103.986L181.977 104.053C181.272 104.49 180.44 104.724 179.588 104.724C178.737 104.724 177.905 104.49 177.2 104.053L177.082 103.986L176.415 92.6146Z"
              fill={color}
            />
            <path
              opacity="0.5"
              d="M176.415 92.6146L182.719 92.6655L182.101 103.986L181.977 104.053C181.272 104.49 180.44 104.724 179.588 104.724C178.737 104.724 177.905 104.49 177.2 104.053L177.082 103.986L176.415 92.6146Z"
              fill="black"
            />
            <path
              d="M182.941 91.958H176.211V92.9259H182.941V91.958Z"
              fill={color}
            />
            <path
              d="M182.941 91.958H176.211V92.9259H182.941V91.958Z"
              fill={color}
            />
            <path
              d="M182.941 91.958H176.211V92.9259H182.941V91.958Z"
              fill={color}
            />
            <g opacity="0.8">
              <path
                opacity="0.2"
                d="M182.941 91.958H176.211V92.9259H182.941V91.958Z"
                fill="white"
              />
            </g>
            <path
              d="M180.624 96.5822C180.46 96.7756 180.237 96.9197 179.983 96.9963C179.73 97.0728 179.457 97.0782 179.2 97.0118C178.943 96.9454 178.713 96.8102 178.54 96.6235C178.368 96.4368 178.26 96.2072 178.231 95.964C178.201 95.7208 178.252 95.4752 178.376 95.2586C178.501 95.0421 178.693 94.8644 178.928 94.7484C179.163 94.6324 179.431 94.5833 179.696 94.6075C179.962 94.6317 180.214 94.7281 180.42 94.8842C180.688 95.0877 180.859 95.3786 180.897 95.6954C180.935 96.0123 180.837 96.3303 180.624 96.5822Z"
              fill={color}
            />
            <path
              opacity="0.6"
              d="M180.821 95.3427C180.929 95.5909 180.948 95.8645 180.873 96.1231C180.799 96.3816 180.636 96.6114 180.408 96.7783C180.18 96.9453 179.899 97.0406 179.607 97.0501C179.315 97.0596 179.028 96.9829 178.788 96.8313C178.659 96.7591 178.551 96.6602 178.472 96.5426C178.304 96.3225 178.218 96.0584 178.227 95.7898C178.236 95.5213 178.34 95.2626 178.523 95.0524C178.706 94.8423 178.958 94.692 179.242 94.6239C179.526 94.5559 179.826 94.5737 180.098 94.6748C180.263 94.7322 180.414 94.8215 180.539 94.9367C180.663 95.052 180.76 95.1904 180.821 95.3427Z"
              fill="white"
            />
            <path
              d="M180.154 95.2351C180.155 95.288 180.139 95.34 180.108 95.3845C180.077 95.4289 180.032 95.4639 179.979 95.4849C179.926 95.5059 179.868 95.512 179.811 95.5024C179.754 95.4928 179.702 95.4679 179.661 95.4309C179.619 95.394 179.591 95.3466 179.579 95.2948C179.568 95.2431 179.573 95.1893 179.595 95.1404C179.617 95.0914 179.654 95.0495 179.702 95.02C179.75 94.9906 179.806 94.9748 179.864 94.9748C179.94 94.9748 180.013 95.002 180.067 95.0507C180.121 95.0994 180.152 95.1656 180.154 95.2351Z"
              fill={color}
            />
            <path
              d="M179.412 110.002H178.603C177.987 110.004 177.378 109.88 176.821 109.639C176.264 109.398 175.773 109.047 175.383 108.61C174.775 107.927 174.441 107.074 174.438 106.193V97.2784H175.673V106.193C175.676 106.812 175.912 107.412 176.341 107.891C176.614 108.198 176.96 108.446 177.351 108.615C177.742 108.784 178.17 108.872 178.603 108.87H179.394L179.412 110.002Z"
              fill="#263238"
            />
            <path
              d="M173.429 226.111C173.347 226.112 173.264 226.097 173.188 226.068C173.112 226.039 173.042 225.996 172.984 225.942C172.869 225.835 172.805 225.692 172.805 225.542C172.805 225.393 172.869 225.25 172.984 225.143L178.93 219.88V104.71C178.93 104.56 178.995 104.416 179.111 104.31C179.226 104.204 179.384 104.144 179.548 104.144C179.711 104.144 179.869 104.204 179.985 104.31C180.1 104.416 180.166 104.56 180.166 104.71V220.117C180.166 220.193 180.15 220.268 180.118 220.338C180.086 220.408 180.039 220.472 179.98 220.525L173.843 225.953C173.732 226.051 173.584 226.108 173.429 226.111Z"
              fill="#263238"
            />
            <path
              d="M185.06 226.111C184.974 226.112 184.89 226.096 184.811 226.065C184.732 226.034 184.661 225.988 184.603 225.93L180.184 221.561V225.121C180.184 225.271 180.119 225.415 180.003 225.521C179.887 225.627 179.73 225.687 179.566 225.687C179.402 225.687 179.245 225.627 179.129 225.521C179.013 225.415 178.948 225.271 178.948 225.121V220.117C178.949 220.004 178.986 219.893 179.056 219.8C179.126 219.706 179.225 219.634 179.34 219.593C179.455 219.551 179.582 219.542 179.702 219.567C179.823 219.592 179.933 219.65 180.017 219.733L185.511 225.16C185.567 225.215 185.611 225.279 185.64 225.349C185.668 225.419 185.682 225.493 185.679 225.568C185.676 225.643 185.657 225.716 185.623 225.784C185.589 225.852 185.54 225.913 185.481 225.964C185.366 226.06 185.216 226.112 185.06 226.111Z"
              fill="#263238"
            />
            <path
              d="M223.018 64.1787C223.019 64.6738 222.86 65.158 222.56 65.5702C222.261 65.9823 221.835 66.3038 221.336 66.4941C220.837 66.6843 220.287 66.7346 219.757 66.6387C219.227 66.5428 218.74 66.305 218.357 65.9554C217.974 65.6057 217.714 65.16 217.608 64.6745C217.502 64.1891 217.555 63.6858 217.762 63.2283C217.969 62.7709 218.319 62.3798 218.768 62.1047C219.217 61.8295 219.746 61.6827 220.286 61.6827C221.01 61.6827 221.703 61.9455 222.216 62.4134C222.728 62.8814 223.016 63.5162 223.018 64.1787Z"
              fill={color}
            />
            <path
              d="M68.2024 69.7255H67.2754V57.5622H80.55V58.4112H68.2024V69.7255Z"
              fill={color}
            />
            <path
              d="M80.55 144.392H67.2754V132.235H68.2024V143.543H80.55V144.392Z"
              fill={color}
            />
            <path
              d="M241.941 69.7255H241.014V58.4112H228.66V57.5622H241.941V69.7255Z"
              fill={color}
            />
            <path
              d="M241.941 144.392H228.66V143.543H241.014V132.235H241.941V144.392Z"
              fill={color}
            />
            <path
              d="M63.3262 53.8492V148.162H245.673V53.8492H63.3262ZM244.728 147.313H64.2717V54.6982H244.728V147.313Z"
              fill={color}
            />
            <path
              opacity="0.5"
              d="M63.3262 53.8492V148.162H245.673V53.8492H63.3262ZM244.728 147.313H64.2717V54.6982H244.728V147.313Z"
              fill="white"
            />
            <path
              d="M227.188 65.2428H226.626V66.788H225.439V62.0393H227.516C227.75 62.0385 227.982 62.0802 228.198 62.1618C228.414 62.2435 228.611 62.3635 228.776 62.515C228.942 62.6665 229.073 62.8465 229.162 63.0446C229.251 63.2428 229.297 63.4551 229.296 63.6693C229.29 63.9572 229.197 64.2379 229.028 64.4806C228.859 64.7233 228.62 64.9186 228.338 65.0447L229.444 66.788H228.165L227.188 65.2428ZM226.626 64.2919H227.516C227.677 64.2718 227.825 64.1987 227.932 64.0864C228.039 63.974 228.099 63.8298 228.099 63.6807C228.099 63.5315 228.039 63.3874 227.932 63.275C227.825 63.1626 227.677 63.0895 227.516 63.0694H226.626V64.2919Z"
              fill="#263238"
            />
            <path
              d="M233.338 65.7579V66.788H230.075V62.0392H233.301V63.0863H231.262V63.8731H233.116V64.9032H231.262V65.7465L233.338 65.7579Z"
              fill="#263238"
            />
            <path
              d="M233.82 64.4278C233.811 64.1007 233.875 63.7752 234.007 63.4712C234.139 63.1673 234.338 62.8912 234.591 62.6598C234.843 62.4284 235.145 62.2465 235.477 62.1252C235.809 62.0039 236.164 61.9458 236.521 61.9544C236.962 61.9491 237.398 62.0471 237.786 62.2393C238.175 62.4315 238.503 62.7114 238.74 63.0524L237.72 63.6184C237.592 63.4359 237.414 63.2875 237.203 63.1881C236.993 63.0887 236.758 63.0419 236.521 63.0524C236.111 63.0524 235.718 63.2015 235.429 63.4669C235.139 63.7322 234.976 64.0921 234.976 64.4674C234.976 64.8427 235.139 65.2026 235.429 65.468C235.718 65.7333 236.111 65.8824 236.521 65.8824C236.758 65.8947 236.994 65.8486 237.205 65.7491C237.416 65.6496 237.594 65.5002 237.72 65.3164L238.74 65.8824C238.505 66.2254 238.177 66.5067 237.788 66.6992C237.4 66.8916 236.963 66.9885 236.521 66.9805C236.156 66.9905 235.793 66.9308 235.454 66.805C235.116 66.6792 234.81 66.4902 234.556 66.25C234.302 66.0098 234.105 65.7236 233.978 65.4099C233.852 65.0961 233.798 64.7617 233.82 64.4278Z"
              fill="#263238"
            />
          </svg>
        );
      }}
    />
  );
};

export default ArtistIcon;
