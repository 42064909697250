import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  container: {
    ...Commons.flexCenter,
    flexDirection: "column",
    position: "relative",
    background: colors.black,
    width: "100vw",
    height: '100vh',
    overflow: 'auto'
  },
  img: {
    width: 180,
    height: "auto",
    [Commons.smallQuery]:{
      width: 150
    }
  },
  title: {
    textAlign: "center",
    fontSize: 48,
    maxWidth: 720,
    margin: '36px 0 0 0',
    color: colors.white,
    [Commons.smallQuery]:{
      margin: '18px 0 0 0',
      fontSize: 30,
      maxWidth: '90vw'
    }
  },
  text: {
    textAlign: "center",
    fontSize: 24,
    maxWidth: 540,
    margin: '12px 0 36px 0',
    color: colors.white,
    [Commons.smallQuery]:{
      fontSize: 18,
      maxWidth: '75vw'
    }
  },
  buttons: {
    ...Commons.flexRow,
    alignItems: 'center',
    gap: 18,
    marginTop: 24,
    [Commons.smallQuery]:{
      flexDirection: 'column',
      width: '90vw'
    }
  },
  button: {
    ...Commons.defaultButton,
    backgroundColor: colors.orange,
    padding: "12px 24px",
    borderRadius: 30,
    color: `${colors.white} !important`,
    textAlign: "center",
    textDecoration: "none !important",
    fontSize: "24px !important",
    '&:first-child': {
      backgroundColor: colors.white,
      color: `${colors.orange} !important`,
    },
    [Commons.smallQuery]:{
      width: '100%'
    }
  },
});

export default styles;
