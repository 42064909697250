import * as React from "react";
import { useParallax } from "react-scroll-parallax";

import useStyles from "./styles";
import LeftNetworkBg from "assets/images/left_network_bg.png";
import RightNetworkBg from "assets/images/right_network_bg.png";

interface ElementsParallaxProps {
  variant: 'leftBg' | 'rightBg';
  speed?: number;
  img?: any;
}

const ElementsParallax = ({ variant, speed, img }: ElementsParallaxProps) => {
  if (variant === "leftBg") {
    return <LeftBg speed={speed} img={img} />;
  } else if (variant === "rightBg") {
    return <RightBg speed={speed} img={img} />;
  }

  return <></>;
};

export default ElementsParallax;

const LeftBg = ({ speed = 50, img }) => {
  const styles = useStyles();

  const { ref: refLeft } = useParallax({
    speed: speed,
    scale: [1.25, 1],
    translateY: [-100, 100],
    targetElement: document.getElementById("root"),
  });
  return (
    <img
      ref={refLeft as any}
      src={img || LeftNetworkBg}
      alt="left_bg"
      className={styles.leftBg}
    />
  );
};

const RightBg = ({ speed = -25, img }) => {
  const styles = useStyles();

  const { ref: refRight } = useParallax({
    speed: speed,
    translateY: [50, -100],
    targetElement: document.getElementById("root"),
  });
  return (
    <img
      ref={refRight as any}
      src={img || RightNetworkBg}
      alt="right_bg"
      className={styles.rightBg}
    />
  );
};
